import React from 'react';

export function InvalidInputsErrorBox({ questions, presentOrGoal }) {
  const invalidItemArray = Object.values(questions).filter(
    (question) => question[presentOrGoal] == null
  );
  if (invalidItemArray.length > 0) {
    return (
      <div className="box error-box">
        <span className="warning-icon">&#9888;</span>
        <p>
          {invalidItemArray.length > 1 && (
            <span>Seuraavista osaamislausekkeista puuttuu vastaus: </span>
          )}
          {invalidItemArray.length == 1 && (
            <span>Seuraavasta osaamislausekkeesta puuttuu vastaus: </span>
          )}
          {invalidItemArray.map((invalidInput) => (
            <span key={invalidInput.id}> {invalidInput.id}</span>
          ))}
        </p>
      </div>
    );
  }
}
