import React from 'react';
import { InfoModal } from './modal/InfoModal.js';
import { levelDescriptions } from './questions.js';

export function Question({
  invalidInputs,
  isQuestionNull,
  competenceGoalsState,
  present,
  question,
  id,
  change,
  headerID,
}) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const inputNamePrefix = competenceGoalsState ? 'GOAL' : 'PRESENT';

  const onOptionChange = (e) => {
    change({ id, value: e.target.value });
  };
  function openModal(e) {
    e.preventDefault();
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const labelTitles = {
    0: `${levelDescriptions[0].header}-${levelDescriptions[0].content}`,
    1: `${levelDescriptions[1].header}-${levelDescriptions[1].content}`,
    2: `${levelDescriptions[2].header}-${levelDescriptions[2].content}`,
    3: `${levelDescriptions[3].header}-${levelDescriptions[3].content}`
  }

  const isInvalid = invalidInputs && isQuestionNull == null;
  return (
    <>
      <fieldset className={isInvalid ? 'fieldset-error' : 'fieldset'}>
        <legend>
          <span className={`${headerID} semi-bold`}>{id} </span>
          <button className="modal-button" onClick={openModal}>
            &#9432;
          </button>{' '}
          {question}{' '}
          {isInvalid && <span className="warning-icon">&#9888;</span>}
        </legend>
        {!competenceGoalsState && (
          <div>
            <input
              type="radio"
              id={`${id}-0`}
              name={`${inputNamePrefix}-${id}-choice`}
              value="0"
              onChange={onOptionChange}
              required
            />
            <label
              title={`${labelTitles[0]}`}
              htmlFor={`${id}-0`}
            >
              0
            </label>

            <input
              type="radio"
              id={`${id}-1`}
              value="1"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label  title={`${labelTitles[1]}`} htmlFor={`${id}-1`}>1</label>

            <input
              type="radio"
              id={`${id}-2`}
              value="2"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label  title={`${labelTitles[2]}`} htmlFor={`${id}-2`}>2</label>

            <input
              type="radio"
              id={`${id}-3`}
              value="3"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label  title={`${labelTitles[3]}`} htmlFor={`${id}-3`}>3</label>
          </div>
        )}
        {competenceGoalsState && (
          <div>
            <input
              type="radio"
              id={`${id}-0`}
              name={`${inputNamePrefix}-${id}-choice`}
              value="0"
              onChange={onOptionChange}
              required
            />
            <label  title={`${labelTitles[0]}`} htmlFor={`${id}-0`}>0</label>

            <input
              type="radio"
              id={`${id}-1`}
              value="1"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label  title={`${labelTitles[1]}`} htmlFor={`${id}-1`}>1</label>

            <input
              type="radio"
              id={`${id}-2`}
              value="2"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label  title={`${labelTitles[2]}`} htmlFor={`${id}-2`}>2</label>

            <input
              type="radio"
              id={`${id}-3`}
              value="3"
              name={`${inputNamePrefix}-${id}-choice`}
              onChange={onOptionChange}
              required="required"
            />
            <label title={`${labelTitles[3]}`} htmlFor={`${id}-3`}>3</label>
          </div>
        )}
        {competenceGoalsState && (
          <p className="present-competence">
            Tämänhetkinen osaaminen: <span className="bold">{present}</span>
          </p>
        )}
      </fieldset>

      <InfoModal
        id={id}
        closeModal={closeModal}
        isOpen={modalIsOpen}
        headerID={headerID}
      />
    </>
  );
}
