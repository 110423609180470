export const initialQuestions = {
  "A1.1": {
    id: "A1.1",
    question:
      "Teen ohjaustyötä noudattaen alani lainsäädäntöä ja hallinnollisia ohjeita.",
    answer: null,
    goal: null,
  },
  "A1.2": {
    id: "A1.2",
    question:
      "Tiedostan ammattini ja osaamiseni rajat ja toimin niiden mukaisesti.",
    answer: null,
    goal: null,
  },
  "A1.3": {
    id: "A1.3",
    question: "Toimin alani eettisten periaatteiden mukaisesti.",
    answer: null,
    goal: null,
  },
  "A1.4": {
    id: "A1.4",
    question:
      "Toimin ohjaussuhteessa alani eettisten periaatteiden mukaisesti.",
    answer: null,
    goal: null,
  },
  "A2.1": {
    id: "A2.1",
    question: "Perustan työni alan keskeisiin teorioihin ja tutkimustietoon.",
    answer: null,
    goal: null,
  },
  "A2.2": {
    id: "A2.2",
    question: "Sovellan monipuolisesti eri teorioita ohjaustyössä.",
    answer: null,
    goal: null,
  },
  "A2.3": {
    id: "A2.3",
    question:
      "Seuraan ohjausalan tutkimusta ja kirjallisuutta sekä työni kannalta relevanttien tahojen ja tieteenalojen julkaisuja.",
    answer: null,
    goal: null,
  },
  "A3.1": {
    id: "A3.1",
    question:
      "Arvioin ja kehitän ohjaustyötäni yhdessä asiakkaiden, kollegoiden, ohjauksen eri toimijoiden ja sidosryhmien kanssa.",
    answer: null,
    goal: null,
  },
  "A3.2": {
    id: "A3.2",
    question:
      "Sitoudun omien kehittämiskohteitteni tunnistamiseen ja ammattitaitoni jatkuvaan kehittämiseen.",
    answer: null,
    goal: null,
  },
  "A4.1": {
    id: "A4.1",
    question:
      "Käytän suullista, kirjallista ja visuaalista viestintää selkeästi ja ymmärrettävästi.",
    answer: null,
    goal: null,
  },
  "A4.2": {
    id: "A4.2",
    question:
      "Hyödynnän viestinnässä ja vuorovaikutuksessa eri kanavia, menetelmiä ja työvälineitä.",
    answer: null,
    goal: null,
  },
  "A4.3": {
    id: "A4.3",
    question:
      "Hallitsen puhutun ja luetun sekä reaaliaikaisen että viivästetyn viestin-nän, dialogin luomisen ja ylläpitämisen asiakkaan/ryhmien kanssa.",
    answer: null,
    goal: null,
  },
  "A4.4": {
    id: "A4.4",
    question:
      "Viestin asiantuntijana asiakastyössä sekä ohjausalan edustajana eri verkostoissa ja työryhmissä.",
    answer: null,
    goal: null,
  },
  "A5.1": {
    id: "A5.1",
    question:
      "Hyödynnän teknologiaa ohjauksessa tehokkaasti ja tarkoituksenmukaisesti.",
    answer: null,
    goal: null,
  },
  "A5.2": {
    id: "A5.2",
    question: "Hyödynnän teknologiaa saumattomasti ohjausprosessissa.",
    answer: null,
    goal: null,
  },
  "A5.3": {
    id: "A5.3",
    question:
      "Edistän yhteisöllistä teknologian käyttöä ohjausprosessissa ja ammatillisessa yhteistyössä.",
    answer: null,
    goal: null,
  },
  "A5.4": {
    id: "A5.4",
    question:
      "Hyödynnän uusia teknologisia ratkaisuja ja edistän niiden käyttöä ohjausprosessissa.",
    answer: null,
    goal: null,
  },
  "B1.1": {
    id: "B1.1",
    question:
      "Tarkastelen ohjausta yksilön oman kehityksen rinnalla palveluprosessina ja yhteiskunnallisena toimintana.",
    answer: null,
    goal: null,
  },
  "B1.2": {
    id: "B1.2",
    question:
      "Otan huomioon ohjauksen tieto-, vuorovaikutus- ja prosessiulottuvuudet.",
    answer: null,
    goal: null,
  },
  "B1.3": {
    id: "B1.3",
    question:
      "Arvioin yhdessä asiakkaan kanssa systemaattisesti ohjausprosessin tulosta ja vaikuttavuutta.",
    answer: null,
    goal: null,
  },
  "B2.1": {
    id: "B2.1",
    question:
      "Tarkastelen ohjausta yksilön näkökulmasta erikseen kehitysprosessina, elämäntilanteena ja sen kontekstina.",
    answer: null,
    goal: null,
  },
  "B2.2": {
    id: "B2.2",
    question:
      "Tarkastelen urakehitystä yksilön ominaisuuksien, kiinnostusten ja arvojen sekä kontekstin viitekehyksessä.",
    answer: null,
    goal: null,
  },
  "B2.3": {
    id: "B2.3",
    question:
      "Tarkastelen työtäni osana asiakkaan tilanteeseen vaikuttavaa yhteiskunnallista ja kulttuurista todellisuutta.",
    answer: null,
    goal: null,
  },
  "B2.4": {
    id: "B2.4",
    question:
      "Sovellan ohjausteorioita ottaen huomioon yksilön sekä hänen kontekstinsa asiakaslähtöisesti.",
    answer: null,
    goal: null,
  },
  "B2.5": {
    id: "B2.5",
    question:
      "Varmistan, että asiakkaat ja ryhmät oppivat arvioimaan ja kehittämään vahvuuksiaan ja osaamistaan suhteessa toimintaympäristön mahdollisuuksiin.",
    answer: null,
    goal: null,
  },
  "B2.6": {
    id: "B2.6",
    question:
      "Tarkastelen omaa ja asiakkaan maailmankatsomusta todellisuuden tulkinnan käsitteellistämisenä, tiedostamisena ja jäsentämisenä.",
    answer: null,
    goal: null,
  },
  "B3.1": {
    id: "B3.1",
    question:
      "Varmistan, että itselläni, asiakkailla ja ryhmillä on käytettävissä ajantasaiset tietolähteet elämää, opiskelua ja työtä koskevien suunnitelmien ja ratkaisujen taustaksi.",
    answer: null,
    goal: null,
  },
  "B3.2": {
    id: "B3.2",
    question:
      "Kartoitan asiakkaan tai ryhmän valmiudet ja ohjaustarpeet sekä asetan ohjausprosessin tavoitteet yhdessä asiakkaan tai ryhmän jäsenten kanssa.",
    answer: null,
    goal: null,
  },
  "B3.3": {
    id: "B3.3",
    question:
      "Suunnittelen ja toteutan yhdessä asiakkaiden kanssa heidän valmiuksiaan ja tarpeitaan vastaavan ohjausprosessin.",
    answer: null,
    goal: null,
  },
  "B3.4": {
    id: "B3.4",
    question:
      "Arvioin yhdessä asiakkaan kanssa ohjausprosessin tulosta ja vaikuttavuutta.",
    answer: null,
    goal: null,
  },
  "C1.1": {
    id: "C1.1",
    question:
      "Otan työssäni huomioon koulutuksen, työelämän sekä yhteiskunnan välisiä ilmiöitä, suhteita, trendejä ja muutoksia.",
    answer: null,
    goal: null,
  },
  "C1.2": {
    id: "C1.2",
    question:
      "Varmistan, että elinikäisen ja jatkuvan oppimisen ytimenä ovat jatkumon muodostavat elinikäisen ohjauksen palvelut.",
    answer: null,
    goal: null,
  },
  "C1.3": {
    id: "C1.3",
    question:
      "Seuraan ajankohtaista tutkimusta ja otan osaa alaan liittyvään yhteiskunnalliseen keskusteluun.",
    answer: null,
    goal: null,
  },
  "C2.1": {
    id: "C2.1",
    question:
      "Sovellan organisaatio- ja verkostoteorioita ohjauksen yhteisten tavoitteiden määrittämisessä sekä palvelujen suunnittelussa, toteutuksessa ja arvioinnissa.",
    answer: null,
    goal: null,
  },
  "C2.2": {
    id: "C2.2",
    question:
      "Kehitän ohjausta osana laajempaa yhteiskunnallista kontekstia ja eri organisaatioiden palvelurakennetta.",
    answer: null,
    goal: null,
  },
  "C3.1": {
    id: "C3.1",
    question: "Teen moniammatillista yhteistyötä muiden ammattilaisten kanssa.",
    answer: null,
    goal: null,
  },
  "C3.2": {
    id: "C3.2",
    question: "Toimin yhteistyössä eri organisaatioiden kanssa.",
    answer: null,
    goal: null,
  },
  "C4.1": {
    id: "C4.1",
    question:
      "Suunnittelen ja organisoin työni paikallisten tarpeiden ja toimintaedellytysten sekä hallinnollisten ohjeiden ja säädösten mukaisesti.",
    answer: null,
    goal: null,
  },
  "C4.2": {
    id: "C4.2",
    question:
      "Edistän ohjauksen systemaattista laadun ja vaikuttavuuden arviointia.",
    answer: null,
    goal: null,
  },
  "C4.3": {
    id: "C4.3",
    question:
      "Tarkastelen ohjauksen taustalla olevia periaatteita, arvoja, käsityksiä, rakenteita, linjauksia ja käytännön toimintaedellytyksiä.",
    answer: null,
    goal: null,
  },
};
export const structure = [
  {
    id: "A",
    header: "Professionaalisuus",
    subheaders: [
      {
        id: "A1",
        subheader: "Eettinen osaaminen",
        questions: ["A1.1", "A1.2", "A1.3", "A1.4"],
      },
      {
        id: "A2",
        subheader: "Teoria- ja tutkimusperustainen osaaminen",
        questions: ["A2.1", "A2.2", "A2.3"],
      },
      {
        id: "A3",
        subheader: "Reflektiivinen osaaminen",
        questions: ["A3.1", "A3.2"],
      },
      {
        id: "A4",
        subheader: "Vuorovaikutus- ja viestintäosaaminen",
        questions: ["A4.1", "A4.2", "A4.3", "A4.4"],
      },
      {
        id: "A5",
        subheader: "Digitaalinen osaaminen",
        questions: ["A5.1", "A5.2", "A5.3", "A5.4", "A5.5"],
      },
    ],
  },
  {
    id: "B",
    header: "Asiakastyöosaaminen",
    subheaders: [
      {
        id: "B1",
        subheader: "Prosessiosaaminen",
        questions: ["B1.1", "B1.2", "B1.3"],
      },
      {
        id: "B2",
        subheader: "Ohjaus- ja oppimisteoreettinen osaaminen",
        questions: ["B2.1", "B2.2", "B2.3", "B2.4", "B2.5", "B2.6"],
      },
      {
        id: "B3",
        subheader: "Menetelmäosaaminen",
        questions: ["B3.1", "B3.2", "B3.3", "B3.4"],
      },
    ],
  },
  {
    id: "C",
    header: "Systeeminen osaaminen",
    subheaders: [
      {
        id: "C1",
        subheader: "Elinikäinen ohjaus",
        questions: ["C1.1", "C1.2", "C1.3"],
      },
      {
        id: "C2",
        subheader: "Systeeminen teoriaosaaminen",
        questions: ["C2.1", "C2.2"],
      },
      {
        id: "C3",
        subheader: "Verkosto-osaaminen",
        questions: ["C3.1", "C3.2"],
      },
      {
        id: "C4",
        subheader: "Strateginen osaaminen",
        questions: ["C4.1", "C4.2", "C4.3"],
      },
    ],
  },
];

export const levelDescriptions = {
  0: {
    header: "Osaamistaso 0",
    content:
      " Ohjaajalla ei vielä ole kyseisen alueen osaamista. Tällainen tilanne voi johtua esimerkiksi siitä, että hän on vastikään saanut kyseistä osaamista sisältävän työtehtävän  vastuulleen.",
  },
  1: {
    header: "Osaamistaso 1",
    content:
      "Ohjaajalla on työssä tarvittavaa perusosaamista. Ohjaaja saavuttaa alan peruskoulutuksessa vähintään tämän tason.",
  },
  2: {
    header: "Osaamistaso 2",
    content:
      "Ohjaajalla on osaamisalueesta hyvä tietoperusta ja hän soveltaa sitä joustavasti ja monipuolisesti työssään.",
  },
  3: {
    header: "Osaamistaso 3",
    content:
      "Ohjaajalla on monipuolista osaamista osaamisalueesta. Hän hallitsee asioita laajasti kokonaisuutena, osaa toimia luovasti ja joustavasti, osaa kehittää osaamisalueeseen kuuluvia asioita ja löytää uusia ratkaisu- ja toimintamalleja.",
  },
};
export const questionInformation = {
  "A1.1": {
    id: "A1.1",
    header: "Laillinen ja hallinnollinen perusta",
    text: "Ohjaaja tekee ohjaustyötä ja tuottaa ohjauspalveluja noudattaen alansa lainsäädäntöä ja hallinnollisia ohjeita.",
    tunnistaa_ja_ymmartaa: [
      "keskeisimmän ohjausta ja sen toimintakontekstia koskevan lainsäädännön",
      "yhdenvertaisuutta ja syrjinnän ehkäisemistä koskevan lainsäädännön",
      "alansa ajantasaisen lainsäädännön sisällön, hallinnolliset ohjeet ja peruskäsitteet",
      "oman organisaation sisäiset toimintaohjeet",
      "kansainväliset sopimukset, joihin Suomi on sitoutunut ja jotka liittyvät ohjauksen järjestämiseen",
    ],
    osaa: [
      "tehdä ohjaustyötä ja tuottaa ohjauspalveluita omaa alaansa koskevan ajantasaisen lainsäädännön ja hallinnollisten ohjeiden mukaisesti",
      "toimia alansa eettisten periaatteiden mukaisesti",
      "dokumentoida asiakasprosessit asianmukaisesti samalla huolehtien asiakastiedon suojaamisesta",
    ],
  },
  "A1.2": {
    id: "A1.2",
    header: "Ammatillinen vastuu",
    text: "Ohjaaja tiedostaa ammattinsa ja osaamisensa rajat ja toimii niiden mukaisesti.",
    tunnistaa_ja_ymmartaa: [
      "oman ammattinsa, ammatillisuutensa ja osaamisensa rajat",
      "mahdolliset tilanteet ja tekijät, joissa ammatin ja oman osaamisen rajat voivat tulla vastaan",
      "oman työkyvyn ja -hyvinvoinnin merkityksen",
    ],
    osaa: [
      "toimia eettisten periaatteiden mukaisesti työssään",
      "ylläpitää ammattimaista ohjaaja-asiakas-vuorovaikutussuhdetta",
      "kuvata oman asiantuntijuutensa ja sen rajat asiakkaalle",
      "toimia ammattinsa ja osaamisensa rajoissa",
      "konsultoida muiden ammattiryhmien edustajia asiakkaan tilanteen niin vaatiessa",
      "ohjata tarvittaessa asiakkaan toisen ammattiryhmän palveluihin",
      "dokumentoida asiakkaan kanssa sovitut toimenpiteet ja päätökset",
      "selkeästi ilmaista viestinnässä, milloin toimii ammattilaisena ja milloin yksityishenkilönä",
      "huolehtia omasta hyvinvoinnistaan ja työkyvystään",
    ],
  },
  "A1.3": {
    id: "A1.3",
    header: "Eettiset periaatteet",
    text: "Ohjaaja toimii alansa eettisten periaatteiden mukaisesti.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen eettiset ja filosofiset lähtökohdat",
      "ohjaustyön eettisen olemuksen",
      "työn sisällöt, palvelukanavat ja tilanteet, joissa saattaa syntyä eettisiä kysymyksiä",
      "omat arvonsa, ihmiskäsityksensä ja eettisyytensä",
      "alansa keskeiset kansainväliset ja kansalliset eettiset periaatteet",
      "teorioiden, lähestymistapojen ja työmuotojen aika- ja kontekstisidonnaisuuden sekä niiden soveltuvuuden kulloiseenkin ohjausprosessiin",
      "eettiseen harkintaan vaikuttavat tekijät eri osapuolten näkökulmista",
      "työtehtävään ja toimintaympäristöön liittyvät jääviyskysymykset",
    ],
    osaa: [
      "toimia ammatillisesti alan kansainvälisten ja kansallisten eettisten periaatteiden mukaisesti",
      "arvioida kriittisesti toimintaansa ohjaajana ja tiedostaa, kuinka omat arvot, ihmiskäsitys ja eettinen ajattelu vaikuttavat ohjaustyöhön",
      "arvioida käyttämiensä teorioiden, lähestymistapojen ja työmuotojen soveltuvuutta kulloiseenkin ohjausprosessiin",
      "toimia työssään siten, että asiakkaan etu on toiminnan keskiössä",
      "toimia työssään edistäen yhdenvertaisuuden, tasa-arvon ja oikeudenmukaisuuden toteutumista",
      "hyödyntää kollegiaalisia keskusteluja eettisten pohdintojen tukena",
      "konsultoida tarvittavia asiantuntijoita eettistä tarkastelua vaativien ratkaisujen niin vaatiessa",
      "tarkastella eri vaihtoehtojen riskejä ja hyötyjä",
      "tehdä työssään itsenäisesti eettisesti perusteltuja ratkaisuja",
      "tuoda esiin eettisesti perusteltuja näkökulmia organisaation ohjaustyössä",
      "tehdä aloitteita eettisen pohdinnan ja toiminnan edistämiseksi omissa ammatillisissa verkostoissaan",
    ],
  },
  "A1.4": {
    id: "A1.4",
    header: "Ohjaussuhde",
    text: "Ohjaaja toimii ohjaussuhteessa eettisten periaatteiden mukaisesti.",
    tunnistaa_ja_ymmartaa: [
      "asiakkaiden moninaisuuden ja erilaiset lähtökohdat (kuten tarpeet, toimintakyky, kyvyt, osaaminen, olosuhteet, arvot, kulttuuri, sukupuoli-identiteetti, uskonto jne.",
      "eturistiriitatilanteet, jotka voivat vaikuttaa ohjaajan objektiivisuuteen asiakkaan ohjausprosessin aikana",
      "asiakkaita koskevien tietojen tallentamista ja säilyttämistä koskevan lainsäädännön sekä oman organisaation ohjeet ja toimintamallit",
    ],
    osaa: [
      "huolehtia siitä, että asiakas on tietoinen laillisista oikeuksistaan ja vastuistaan",
      "toimia eturistiriitatilanteissa ammattieettisten periaatteiden mukaisesti",
      "toimia tilanteissa, joissa asiakas tuo esille huolen, ettei ohjaaja toimi eettisten periaatteiden mukaisesti",
      "edistää työssään saavutettavuutta ja yhdenvertaisuutta",
      "kertoa asiakkaille tietojen tallentamisen lähtökohdat ja periaatteet",
      "kertoa asiakkaille, minne heitä koskevia tietoja tallennetaan, miten ne suojataan ja kuinka kauan niitä säilytetään",
      "varmistaa, että asiakas antaa luvan tarvittavien tietojen tallentamiseen",
      "varmistaa, että asiakas antaa luvan tietojen jakamiseen tarvittaessa eri toimijoiden kesken",
    ],
  },
  "A2.1": {
    id: "A2.1",
    header: "Teoriatieto",
    text: "Ohjaaja perustaa työnsä alan keskeisiin teorioihin ja tutkimustietoon. Teoriatiedon kautta ohjaaja voi myös hahmottaa, mitkä ovat hänen ohjaustyönsä tavoitteet ja millaisilla lähestymistavoilla ne on mahdollista saavuttaa.",
    tunnistaa_ja_ymmartaa: [
      "ohjausalan monialaisen tietoperustan, joka sisältää erilaisia tieteenaloja ja näkökulmia",
      "ohjausalan keskeisimmät teoriat ja niiden erilaiset lähestymistavat",
      "ohjaustyötä sivuavien tieteenalojen keskeisimmät teoriat ja lähestymistavat",
    ],
    osaa: [
      "kuvata ohjausalan monitieteisen tietoperustan",
      "kuvata ohjausalan keskeisimpien teorioiden perusteet ja niiden soveltamisen ohjaustyössä",
      "kuvata ohjausalaa sivuavien eri tieteenalojen keskeisimmät teoriat ja niiden merkityksen ohjaustyössä",
      "pysyä ajan tasalla alan teoreettisen tutkimuksen kehityksestä",
    ],
  },
  "A2.2": {
    id: "A2.2",
    header: "Teoriatiedon soveltaminen",
    text: "Ohjaaja soveltaa monipuolisesti eri teorioita ohjaustyössä. Ohjaaja valitsee asiakkaan tilanteen kannalta tarkoituksenmukaisimmat teoriat, työmuodot ja -menetelmät.",
    tunnistaa_ja_ymmartaa: [
      "alan keskeisimmät teoriat ja lähestymistavat ja tutkimustietoon perustuvat lähtökohdat",
      "valitsemiensa työmuotojen, lähestymistapojen ja interventioiden taustalla olevat teoreettiset perusteet ja niiden soveltamisen asiakastyössä",
    ],
    osaa: [
      "integroida ohjauksen teoreettiset lähestymistavat luontevaksi osaksi ohjaustyötään",
      "kuvata, miten hän soveltaa teoriatietoa omassa työssään",
      "kuvata ne alan keskeisimpien perusteorioiden lähtökohdat, joiden perusteella hän valitsee asiakkaan tilanteen kannalta tarkoituksenmukaisimmat työmenetelmät",
      "muodostaa teoreettisen tietämyksensä perusteella soveltuvan käyttöteorian, johon perustaa ohjaustyönsä",
      "kerätä riittävästi tietoa asiakkaan ja ryhmien tilanteesta, jotta voi valita tarkoituksenmukaiset työmuodot",
      "valita teoriaan perustuvat tarkoituksenmukaisimmat työmuodot",
      "arvioida ja tarvittaessa päivittää omia työtapojaan ja -menetelmiään uusimman tutkimustiedon perusteella",
    ],
  },
  "A2.3": {
    id: "A2.3",
    header: "Ohjausalan tutkimus",
    text: "Ohjaaja seuraa ohjausalan tutkimusta ja kirjallisuutta sekä työnsä kannalta relevanttien tahojen ja tieteenalojen julkaisuja. Alan tutkimuksen seuraaminen kytkeytyy läheisesti muihin osaamisiin ja oman ammatillisen osaamisen jatkuvaan kehittämiseen",
    tunnistaa_ja_ymmartaa: [
      "ohjausalan kannalta keskeisimmät ja kattavimmat tietolähteet sekä tutkimusorganisaatiot ja -yhteisöt, jotka tuottavat relevanttia tietoa ohjausalasta",
    ],
    osaa: [
      "hankkia ohjaustyön kannalta keskeistä tutkimustietoa",
      "arvioida tutkimustiedon tarkoituksenmukaisuutta oman työn kannalta",
      "soveltaa hankkimaansa tutkimustietoa käytännön ohjaustyössä sekä ohjauspalvelujen suunnittelussa, toteutuksessa ja arvioinnissa",
      "edistää omaa työtään ja sen toimintaedellytyksiä koskevan tutkimusaineiston tuottamista, suunnittelua ja toteutusta",
      "soveltaa tutkimuksellista otetta ja perusmetodeja oman työnsä kehittämisessä",
      "jakaa hankkimaansa tutkimustietoa kollegoille ja yhteistyötahoille",
    ],
  },
  "A3.1": {
    id: "A3.1",
    header: "Reflektiivinen ammattikäytäntö",
    text: "Ohjaaja arvioi kriittisesti sekä kehittää ja uudistaa ohjaustyötään yhdessä asiakkaiden, kollegoiden, ohjauksen eri toimijoiden ja sidosryhmien kanssa.",
    tunnistaa_ja_ymmartaa: [
      "kriittisen reflektion perusperiaatteet ja osatekijät",
      "ohjaajana toimimiseen liittyviä peruskäsityksiä, arvoja ja asenteita sekä ajattelu- ja toimintamalleja",
      "oman ammattinsa ja osaamisensa rajat",
      "ohjauksen järjestämiseen liittyvät taustatekijät ja yhteiskunnalliset lähtökohdat",
      "ohjauksen systeemisen kokonaisluonteen",
    ],
    osaa: [
      "arvioida oman toimintansa eettisiä, teoreettisia ja filosofisia lähtökohtia sekä niiden vaikutusta ohjaustyöhön",
      "tarkastella kriittisesti omaa toimintaansa erilaisissa ohjaussuhteissa ja -prosesseissa alan eettisen periaatteiden ja lähtökohtien mukaisesti",
      "arvioida oman osaamisensa ja ammatillisen identiteettinsä kehittymistä sekä tunnistaa omat vahvuutensa ja kehittämiskohteensa",
      "yksilöidä ja arvioida oman toimintansa tulokset yksilön, ohjausprosessin, oman organisaation ja yhteiskunnallisen vaikuttavuuden näkökulmasta",
      "kriittisesti arvioida ja uudistaa omaa ohjaustyötään yhteistyössä asiakkaiden, eri yhteistyötahojen ja sidosryhmien kanssa",
      "kuvata ohjauksen aseman osana oman organisaation ja hallinnonalan perustehtävää",
    ],
  },
  "A3.2": {
    id: "A3.2",
    header: "Oman osaamisen kehittäminen",
    text: "Ohjaaja sitoutuu omien kehittämiskohteidensa tunnistamiseen ja ammattitaitonsa jatkuvaan kehittämiseen",
    tunnistaa_ja_ymmartaa: [
      "elinikäisen oppimisen ja ammattitaidon jatkuvan kehittämisen merkityksen omassa työssään",
      "työhönsä liittyvät tavoitteet, sisällöt ja osa-alueet sekä osaamisvaatimukset",
      "nykyisen ja tulevaisuuden teknologian mahdollisuudet ohjaustyössä",
      "oman kehittymisensä kannalta merkityksellisen koulutustarjonnan",
      "rahoitusmahdollisuudet, joiden avulla on mahdollista keskittyä oman osaamisen kehittämiseen",
      "työkykyyn ja hyvinvointiin vaikuttavia tekijöitä",
    ],
    osaa: [
      "kriittisesti arvioida omaa ja työyhteisön ohjaustoimintaa suhteessa toimintaympäristön muutoksiin ja megatrendeihin",
      "arvioida lainsäädännön, toimintaympäristön ja oman organisaation toimintaohjeiden vaikutuksia oman työnsä näkökulmasta",
      "päivittää omaa työnkuvaansa ja osaamistaan vastaamaan tehtävässään ilmeneviä muutostarpeita",
      "arvioida tämän päivän ja tulevaisuuden teknologian mahdollisuuksia ja käyttöä ohjaustyössään",
      "arvioida omaa suoriutumistaan työssään ja tunnistaa omat vahvuudet ja kehittämiskohteet",
      "laatia ja ylläpitää henkilökohtaista kehittämissuunnitelmaa",
      "hakeutua täydennyskoulutukseen, joka tukee oman osaamisen kehittymistä",
      "kehittää omaa toimintaansa asiakkailta ja yhteistyötahoilta saadun palautteen perusteella",
      "arvioida oman osaamisen ja ammattitaidon kehittymistä",
      "jakaa kokemuksiaan omissa ammatillisissa verkostoissaan ja osallistuu ohjausalan ammatilliseen keskusteluun",
      "arvioida ja edistää omaa työkykyään ja hyvinvointiaan"
    ],
  },
  "A4.1": {
    id: "A4.1",
    header: "Selkeä viestintä",
    text: "Ohjaaja käyttää suullista, kirjallista ja visuaalista viestintää selkeästi ja ymmärrettävästi. Hän viestii erilaisissa vuorovaikutustilanteissa läpinäkyvästi ja saavutettavalla tavalla sekä osaa huomioida asiakkaiden erilaiset valmiudet vastaanottaa ja ymmärtää ohjaajan viestintää.",
    tunnistaa_ja_ymmartaa: [
      "saavutettavuuden merkityksen viestinnässä",
      "tehokasta viestintää edistävät ja rajoittavat tekijät",
      "monilukutaidon merkityksen työssään",
      "erilaisten viestintäkanavien mahdollisuudet ja rajoitukset viestinnässä",
      "ohjaajien ja asiakkaiden erilaisten taustatekijöiden (esim. kieli, arvot, kulttuuri, elämänkokemukset, opiskelukyky) mahdolliset vaikutukset viestintään",
      "kulttuuritietoisen viestinnän keskeiset lähtökohdat",
      "verbaalisen ja nonverbaalisen viestinnän merkityksen",
      "tarkoituksenmukaisen ja selkeän kirjoitustyylin sekä tekstin muotoilun merkityksen",
      "visuaalisen viestinnän merkityksen ohjaustyössä",
    ],
    osaa: [
      "käyttää asiakkaan ja ryhmien kanssa tarkoituksenmukaista kieltä ja viestintäkanavaa",
      "huomioida eri-ikäisten ja erilaisten asiakkaiden taidot ja valmiudet toimia vuorovaikutuksessa",
      "säädellä oman kielen sävyä, nopeutta ja voimakkuutta",
      "varmistaa, että non-verbaalinen viestintä, kuten eleet ja ilmeet, tukee ja täydentää sanallista viestintää",
      "välttää vaikeaselkoista kieltä, lyhenteitä tai ammattitermejä viestinnässä",
    ],
  },
  "A4.2": {
    id: "A4.2",
    header: "Tavoitteellinen vuorovaikutus",
    text: "Ohjaaja hyödyntää viestinnässä ja vuorovaikutuksessa eri kanavia, menetelmiä ja työvälineitä.",
    tunnistaa_ja_ymmartaa: [
      "ammatillisen vuorovaikutuksen ja epävirallisen viestinnän erot",
      "ammatillisen vuorovaikutuksen mahdollisuudet ja rajat ohjauksessa",
      "että ohjaajan orientoituminen vuorovaikutus- ja viestintätilanteeseen luo perustan tavoitteelliselle ohjausprosessille",
      "aktiivista viestin vastaanottamista edistävät ja rajoittavat tekijät",
      "ohjaajien ja asiakkaiden erilaisten taustatekijöiden (esim. kieli, kielen rekisteri, arvot, kulttuuri, elämänkokemukset) mahdolliset vaikutukset keskinäiseen viestintään ja viestien merkitysten tulkintaan",
      "verbaalisen ja nonverbaalisen viestinnän merkityksen",
      "erilaisten kysymystyyppien käytön merkityksen ohjausprosessissa",
    ],
    osaa: [
      "rakentaa monikanavaisen saavutettavan, toimivan ja tehokkaan ohjaustilanteen, -prosessin ja -ympäristön asiakkaan tarpeiden mukaisesti",
      "toimia vuorovaikutustilanteessa, jossa ohjausprosessin tavoitteet ovat keskiössä",
      "rakentaa luottamuksellisen ohjaussuhteen ja ylläpitää sitä vuorovaikutuksen avulla",
      "ottaa aktiivisesti vastaan toisen tai ryhmän jäsenten viestejä",
      "selventää puhuttua ja kirjoitettua kieltä tarvittaessa",
      "muodostaa ymmärryksen asiakkaan tai ryhmän jäsenten viestinnästä",
      "arvioida, mitä asiakas tai ryhmä haluaa viestiä",
      "välttää omiin oletuksiin perustuvien tulkintojen tekemistä ",
      "varmistaa oman sanallisen ja sanattoman viestin yhdenmukaisuutta",
      "välittää herkkyyttä, kiinnostusta ja tarkkaavaisuutta yksilöohjauksessa ja ryhmätilanteissa",
      "kehittää vuorovaikutusta ja yhteistä tiedonmuodostusta",
      "edistää jaetun ymmärryksen muodostumista sekä yhteistä luottamusta ja hyväksyntää",
      "huomioida asiakkaan ja ryhmän non-verbaalista viestintää ja reagoida siihen asianmukaisesti",
      "kunnioittaa toisten henkilökohtaista fyysistä tilaa ja pitää yllä tarvittavaa etäisyyttä vuorovaikutustilanteessa",
    ],
  },
  "A4.3": {
    id: "A4.3",
    header: "Refleksiivinen viestintä",
    text: "Ohjaaja hallitsee puhutun ja luetun sekä reaaliaikaisen että viivästetyn viestinnän, mikä mahdollistaa dialogin ohjaajan ja asiakkaan/ryhmien välillä. Samat periaatteet koskevat kaikkia ammatillisia vuorovaikutustilanteita, joihin ohjaaja osallistuu.",
    tunnistaa_ja_ymmartaa: [
      "refleksiivisen viestinnän merkityksen prosesseissa, joissa on kysymys asiakkaiden ja ryhmien reflektiosta, muutoksesta ja kehityksestä",
      "erilaisten kysymysmuotojen vaikutukset ohjausprosessiin, asiakkaiden ja ryhmien toimintaan sekä kokemuksiin ohjaustilanteissa",
      "erilaisten interventioiden vaikutukset ohjausprosessiin ja tavoitteiden saavuttamiseen",
      "ohjaajan oman ja asiakkaan ajattelun, toiminnan, tunteiden ja taustaoletusten merkityksen ohjauksessa ",
      "kulttuurin, kasvuympäristön ja ympäröivän yhteiskunnan vaikutuksen yksilön ajatusmaailmaan ja toimintaan",
      "kriittisen reflektoinnin merkityksen vuorovaikutustilanteissa",
    ],
    osaa: [
      "tulkita muuttuvaa maailmaa ympärillään ja hahmottaa sen kulttuurista monimuotoisuutta",
      "hyödyntää asiakkaan tai ryhmän kanssa tarkoituksenmukaisia kysymyksiä ja vuorovaikutuksen keinoja ",
      "vahvistaa asiakkaalle ymmärtäneensä hänen viestinsä ja näkökulmansa",
      "tehdä yhteenvetoja dialogista",
      "ohjata asiakasta tarkastelemaan ajatuksiaan, tunteitaan ja käyttäytymistään sekä edistämään itsetuntemustaan",
      "käyttää kysymysmuotojen lisäksi ohjauksellisia vuorovaikutuksen työvälineitä, kuten selventämistä, peilaamista, palautetta, kohdentamista ja yhteenvetämistä",
      "käyttää tarkoituksenmukaisesti konfrontaatiota tilanteissa, joissa se voi edistää asiakkaan tai ryhmän reflektiota ja tavoitteiden saavuttamista",
      "keskittyä vuorovaikutustilanteeseen ja huomioida tekijät, jotka voivat edistää tai häiritä asiakkaan tai ryhmän keskittymistä",
    ],
  },
  "A4.4": {
    id: "A4.4",
    header: "Asiantuntijaviestintä",
    text: "Ohjaaja viestii asiantuntijana muun muassa asiakastyössä tai ohjauksellisten kysymysten yhteisöllisessä tarkastelussa eri verkostoissa. Hän voi toimia ammattialan edustajana myös opetustehtävissä, julkisissa tiedotustilaisuuksissa, julkaisuissa, työryhmien jäsenenä sekä kollegiaalisessa ja monialaisessa yhteistyössä.",
    tunnistaa_ja_ymmartaa: [
      "kohderyhmästä ja asiayhteydestä riippuvan tarkoituksenmukaisen esitystyylin ja sisällön muotoilun merkityksen",
      "yhteiseen työskentelyyn osallistuvien henkilöiden taustojen ja eri näkökulmien merkityksen vuorovaikutuksen kannalta",
      "yhteiskunnallisen vuorovaikutuksen merkityksen ohjauksen perustehtävän kannalta",
    ],
    osaa: [
      "työskennellä erilaisten ihmisten kanssa ja ylläpitää ammatillisia suhteita ja verkostoja",
      "toimia viestintätilanteissa oman ammattialansa ja organisaationsa edustajana",
      "tehdä yhteenvetoja ja tiivistyksiä joko ohjausprosessista tai yhteisistä keskusteluista",
      "valita esittämänsä asian keskeisimmät painotukset",
      "määrittää viestinsä tavoitteen ja sisällön kohderyhmän mukaisesti",
      "esittää asiat vakuuttavasti erilaisissa julkisissa konteksteissa",
      "osallistua aktiivisesti yhteiseen työskentelyyn mukana olevien tahojen kanssa tehtyjen sopimusten mukaisesti",
      "edistää ja kehittää omaa viestintäänsä siten, että työskentely työyhteisön ryhmissä ja monialaisissa verkostoissa on tavoitteellista, toimivaa ja tehokasta",
    ],
    context_competencies: [
      {
        context: "Perusopetus",
        tunnistaa_ja_ymmartaa: [
          "monialaisen, moniammatillisen ja huoltajien sekä työnantajien kanssa tehtävän yhteistyön edellyttämien vuorovaikutus- ja viestintätaitojen merkityksen työssään",
          "eri-ikäisten ja erilaisten oppilaiden taidot ja valmiudet toimia vuorovaikutuksessa sekä viestinnän ja tiedottamisen vastaanottajina",
        ],
        osaa: [
          "suunnitella ja toteuttaa oppitunteja sekä ryhmä- ja vuorovaikutustilanteita ja viestintää ottamalla huomioon eri-ikäisten ja erilaisten oppilaiden taidot ja valmiudet",
          "toimia monialaisissa, moniammatillisissa ja huoltajien sekä työnantajien kanssa tapahtuvissa vuorovaikutustilanteissa",
          "kuvailla selkeästi oppilaille ja huoltajille ohjaus- ja opiskeluhuoltopalvelujen sisältöjä ja saatavuutta",
        ],
      },
      {
        context: "Toinen aste",
        tunnistaa_ja_ymmartaa: [
          "monialaisen, moniammatillisen ja huoltajien sekä työnantajien kanssa tehtävän yhteistyön edellyttämien vuorovaikutus- ja viestintätaitojen merkityksen työssään",
          "eri-ikäisten ja erilaisten opiskelijoiden taidot ja valmiudet toimia vuorovaikutuksessa sekä viestinnän ja tiedottamisen vastaanottajina",
          "koulutuksen markkinointia koskevat periaatteet ja eettiset kysymykset",
        ],
        osaa: [
          "suunnitella ja toteuttaa oppitunteja, ryhmä- ja vuorovaikutustilanteita sekä viestintää ottamalla huomioon eri-ikäisten ja erilaisten opiskelijoiden taidot ja valmiudet",
          "toimia monialaisissa, moniammatillisissa ja huoltajien sekä työnantajien kanssa tapahtuvissa vuorovaikutustilanteissa",
          "kuvailla selkeästi opiskelijoille ja huoltajille sekä yhteistyötahoille ohjaus- ja tukipalvelujen sisältöjä ja saatavuutta",
          "esitellä oppilaitoksen toimintaa ja koulutustarjontaa monipuolisesti ja selkeästi",
          "huomioida markkinoinnin kohderyhmien erilaiset valmiudet ottaa viestiä vastaan",
        ],
      },
      {
        context: "Korkea-aste",
        tunnistaa_ja_ymmartaa: [
          "monialaisen ja moniammatillisen yhteistyön edellyttämien vuorovaikutus- ja viestintätaitojen merkityksen työssään",
          "eri-ikäisten ja erilaisten opiskelijoiden taidot ja valmiudet toimia vuorovaikutuksessa sekä viestinnän ja tiedottamisen vastaanottajina",
          "koulutuksen markkinointia koskevat periaatteet ja eettiset kysymykset",
        ],
        osaa: [
          "suunnitella ja toteuttaa vuorovaikutustilanteita ja viestintää ottamalla huomioon eri-ikäisten ja erilaisten opiskelijoiden taidot ja valmiudet",
          "toimia monialaisissa ja moniammatillisissa vuorovaikutustilanteissa",
          "kuvailla selkeästi opiskelijoille ja yhteistyötahoille ohjaus- ja tukipalvelujen sisältöjä ja saatavuutta",
          "esitellä oppilaitoksen toimintaa ja koulutustarjontaa monipuolisesti ja selkeästi",
          "huomioida markkinoinnin kohderyhmien erilaiset valmiudet ottaa viestiä vastaan",
        ],
      },
      {
        context: "Työvoimapalvelut",
        tunnistaa_ja_ymmartaa: [
          "monialaisen ja moniammatillisen sekä työelämäyhteistyön edellyttämien vuorovaikutus- ja viestintätaitojen merkityksen työssään",
          "eri-ikäisten ja erilaisten asiakkaiden taidot ja valmiudet toimia vuorovaikutuksessa sekä viestinnän ja tiedottamisen vastaanottajina",
        ],
        osaa: [
          "suunnitella ja toteuttaa vuorovaikutustilanteita ja viestintää ottamalla huomioon eri-ikäisten ja erilaisten asiakkaiden taidot ja valmiudet",
          "toimia monialaisissa ja moniammatillisissa vuorovaikutustilanteissa",
          "kuvailla selkeästi asiakkaille ja yhteistyötahoille ohjaus- ja muiden palvelujen sekä tukimuotojen sisältöjä ja saatavuutta",
        ],
      },
      {
        context: "Ohjaamot",
        tunnistaa_ja_ymmartaa: [
          "monialaisen ja moniammatillisen sekä työelämäyhteistyön edellyttämien vuorovaikutus- ja viestintätaitojen merkityksen työssään",
          "eri-ikäisten ja erilaisten asiakkaiden taidot ja valmiudet toimia vuorovaikutuksessa sekä viestinnän ja tiedottamisen vastaanottajina",
        ],
        osaa: [
          "suunnitella ja toteuttaa vuorovaikutustilanteita ja viestintää ottamalla huomioon eri-ikäisten ja erilaisten asiakkaiden taidot ja valmiudet",
          "toimia monialaisissa ja moniammatillisissa vuorovaikutustilanteissa",
          "kuvailla selkeästi asiakkaille ja yhteistyötahoille ohjaus- ja muiden palvelujen sekä tukimuotojen sisältöjä ja saatavuutta",
        ],
      },
    ],
  },
  "A5.1": {
    id: "A5.1",
    header: "Teknologia ohjauksessa",
    text: "Ohjaaja hyödyntää digitaalista teknologiaa ohjauksessa tehokkaasti ja tarkoituksenmukaisesti.",
    tunnistaa_ja_ymmartaa: [
      "digitaalisen teknologian roolin ohjaustyössä ja sen vaikutukset ohjausprosessiin",
      "erilaiset digitaaliset työkalut ja ratkaisut, jotka ovat relevantteja ohjausprosessissa",
      "asiakkaiden mahdolliset erilaiset tarpeet ja edellytykset digitaalisen teknologian käytölle ohjauksessa",
      "digitaalisten ohjausmateriaalien saavutettavuuden merkityksen",
      "digitaalisen teknologian mahdollisuudet ja rajoitukset ohjaustyössä",
      "tietoturvan ja yksityisyyden suojan merkityksen digitaalisen teknologian käytössä ohjaustyössä",
      "digitaalisen teknologian roolin oman ammatillisen kehittymisen tukena ja sen vaikutuksen osaamisen laajentamiseen ja päivittämiseen",
    ],
    osaa: [
      "valita sopivat, asiakkaan tai ryhmän tarpeiden kannalta tarkoituksenmukaisimmat, digitaaliset työkalut ja ratkaisut eri ohjaustilanteisiin",
      "valita ja käyttää saavutettavia digitaalisia ohjausmateriaaleja, jotka ovat esteettömiä",
      "käyttää digitaalista teknologiaa tietojen tallentamiseen ja asiakastietojen suojaamiseen, noudattaen voimassa olevia tietosuojasäännöksiä ja -käytäntöjä",
      "arvioida digitaalisen teknologian vaikuttavuutta ohjausprosessissa ja tarvittaessa tehdä muutoksia käytettyihin teknologioihin parantaakseen ohjausprosessin laatua",
      "hyödyntää digitaalista teknologiaa oman ammatillisen osaamisensa kehittämisessä, esimerkiksi osallistumalla verkkokoulutuksiin, seuraamalla alaan liittyvää verkkosisältöä ja osallistumalla ammatillisiin verkostoihin",
      "noudattaa tietoturva- ja yksityisyyden suojan periaatteita digitaalisen teknologian käytössä ja varmistaa asiakkaiden tietojen turvallisen käsittelyn ja suojan",
      "kehittää jatkuvasti omaa osaamistaan ja pysyä ajan tasalla teknologian kehityksestä ja sen vaikutuksista ohjaustyöhön, mukauttaen omaa toimintaansa ja menetelmiään vastaamaan teknologian tuomia mahdollisuuksia ja haasteita",
    ],
  },
  "A5.2": {
    id: "A5.2",
    header: "Teknologia ohjausprosessissa",
    text: "Ohjaaja hyödyntää digitaalista teknologiaa saumattomasti ohjausprosessissa.",
    tunnistaa_ja_ymmartaa: [
      "kuinka eri digitaaliset työkalut ja sovellukset voivat tukea eri ohjausvaiheita, kuten tiedonkeruuta, tavoitteiden asettamista ja seurantaa",
      "eri digitaalisten vuorovaikutuskanavien, kuten videopuheluiden, chattien ja sähköpostin, erityispiirteet ja soveltuvuuden ohjaustilanteisiin",
      "digitaalisten ohjausmateriaalien saavutettavuuden merkityksen",
      "asiakkaiden erilaiset tarpeet ja preferenssit digitaalisen teknologian käytössä ohjauksessa",
      "digitaalisen teknologian mahdollisuudet ja haasteet ohjausprosessin aikana",
      "kuinka integroida digitaalinen teknologia saumattomasti osaksi ohjausta, niin että se tukee ohjausprosessia ja asiakkaan tai ryhmien tavoitteiden saavuttamista",
    ],
    osaa: [
      "valita ja hyödyntää tarkoituksenmukaisia digitaalisia työkaluja ja sovelluksia ohjausprosessin eri vaiheisiin, ohjaustilanteisiin ja asiakasryhmille",
      "valita ja käyttää saavutettavia digitaalisia ohjausmateriaaleja, jotka ovat esteettömiä",
      "käyttää digitaalista teknologiaa tiedonkeruussa ja analysoinnissa asiakkaan tilanteen ymmärtämiseksi ja ohjausprosessin suunnittelemiseksi",
      "tarjota asiakkaille tukea ja ohjausta digitaalisen teknologian käytössä, jos se on heille uutta tai haasteellista",
      "soveltaa hyviä vuorovaikutustaitoja ja -käytäntöjä digitaalisessa ympäristössä, kuten selkeä ja ymmärrettävä viestintä, aktiivinen kuuntelu ja empatia",
      "arvioida digitaalisen teknologian vaikuttavuutta ohjausprosessissa ja tarvittaessa muokata sen käyttöä, sisältöjä ja menetelmiä",
      "luoda ja ylläpitää digitaalista ympäristöä, joka tukee ohjausprosessia ja asiakkaisen tavoitteiden saavuttamista",
      "pitää digitaalisen teknologian osana ohjausprosessin jatkuvaa kehittämistä ja sopeutua teknologian muutoksiin",
    ],
  },
  "A5.3": {
    id: "A5.3",
    header: "Teknologian yhteisöllinen käyttö",
    text: "Ohjaaja edistää yhteisöllistä digitaalisen teknologian käyttöä ohjausprosessissa, mahdollistaen asiakkaille tai muille sidosryhmille ryhmäpohjaisen ohjauksen ja vertaistuen. Hän käyttää digitaalisen teknologian mahdollisuuksia ja työvälineitä ammatilliseen yhteistyöskentelyyn yhteisten tavoitteiden saavuttamiseksi.",
    tunnistaa_ja_ymmartaa: [
      "kuinka digitaalisen teknologian avulla voidaan tukea yhteisöllistä ohjausta ja vertaisoppimista, ja miten erilaiset teknologiat mahdollistavat ryhmäpohjaisen vuorovaikutuksen ja tiedon jakamisen",
      "kuinka luoda turvallinen ja kannustava ympäristö ryhmäpohjaiseen digitaalisen teknologian käyttöön",
      "kuinka ottaa huomioon erilaiset osallistujien digitaalisen teknologian käyttöön liittyvät valmiudet ja tarpeet",
      "miten digitaalinen teknologia voi edistää ryhmädynamiikkaa ja yhteisön rakentumista ohjausprosessin aikana",
      "digitaalisen teknologian mahdollisuudet ammatillisessa yhteistyöskentelyssä yhteisten tavoitteiden saavuttamiseksi",
    ],
    osaa: [
      "suunnitella ja toteuttaa yhteisöllistä ohjausta digitaalisen teknologian avulla, valitsemalla työvälineet ja menetelmät ryhmän tarpeiden mukaan",
      "ohjata ryhmää digitaalisen teknologian käytössä ja tarjota tukea osallistujille tarvittaessa",
      "luoda turvallisen ja avoimen ilmapiirin, joka kannustaa osallistujia jakamaan kokemuksiaan ja oppimaan toisiltaan",
      "soveltaa hyviä vuorovaikutustaitoja ja -käytäntöjä digitaalisessa ympäristössä, kuten selkeä ja ymmärrettävä viestintä, aktiivinen kuuntelu ja empatia",
      "hyödyntää erilaisia fasilitointimenetelmiä vuorovaikutuksen edistämiseksi digitaalisissa ympäristöissä",
      "huomioida erilaiset oppimistavat ja tarjota monipuolisia digitaalista teknologiaan hyödyntäviä oppimismahdollisuuksia ryhmälle",
      "seurata ja arvioida yhteisöllisen, ryhmäpohjaisen digitaalisen teknologian käytön vaikutuksia ohjausprosessiin ja tarvittaessa tehdä muutoksia asetettujen tavoitteiden sekä ryhmän tarpeiden mukaisesti",
      "käyttää digitaalisen teknologian mahdollisuuksia ja työvälineitä ammatillisessa yhteistyöskentelyssä yhteisten tavoitteiden saavuttamiseksi",
    ],
  },
  "A5.4": {
    id: "A5.4",
    header: "Teknologian ohjauskäytön systemaattinen kehittäminen",
    text: "Ohjaaja hyödyntää alalla olevia uusia teknologisia ratkaisuja ja edistää niiden käyttöä ohjausprosessissa.",
    tunnistaa_ja_ymmartaa: [
      "digitaalisen teknologian nopean kehityksen ja sen vaikutukset ohjaustyöhön",
      "kuinka digitaalinen teknologia voi tukea ohjausprosessin parantamista ja asiakaspalvelun laadun nostamista",
      "kuinka seurata ja arvioida digitaalisen teknologian käytön vaikuttavuutta ja tarvittaessa tehdä muutoksia",
      "kuinka digitaalisen teknologian integrointi ohjaustyöhön voi vaikuttaa oman ammatillisen kehittymisen mahdollisuuksiin",
    ],
    osaa: [
      "seurata digitaalisen teknologian kehitystä ja pysyä tietoisena uusista teknologisista ratkaisuista, jotka voivat tukea ohjausprosessia",
      "arvioida omassa ohjaustyössään, miten digitaalinen teknologia voisi parantaa palveluiden saatavuutta ja laatua",
      "kehittää omaa osaamistaan ja ottaa aktiivisesti haltuun uusia teknologisia taitoja, työkaluja ja sovelluksia",
      "kuunnella ja ottaa huomioon asiakkaiden ja kollegoiden palautetta digitaalisen teknologian käytöstä ja kehittää ohjaustyötään sen perusteella",
      "pysyä ajan tasalla alan parhaista käytännöistä ja jakaa tietoa kollegoidensa kanssa digitaalisen teknologian tehokkaasta hyödyntämisestä ohjaustyössä",
    ],
  },
  "B1.1": {
    id: "B1.1",
    header: "Ohjausprosessin osatekijät",
    text: "Ohjaaja tarkastelee ohjaustyötä asiakkaan kehitys- ja muutosprosessin rinnalla moniammatillisena, monialaisena ja monihallinnollisena palveluprosessina ja yhteiskunnallisena toimintana. Ohjauksessa käytettävät monikanavaiset palvelut voivat sisältää käyntiasiointia, reaaliaikaista tai viivästettyä etäpalvelua, videoneuvotteluja tai puhelinpalveluja.",
    tunnistaa_ja_ymmartaa: [
      "yksilöiden ja ryhmien erilaiset lähtökohdat ja valmiudet omaa tulevaisuutta koskevaan suunnitteluun ja päätöksentekoon",
      "yksilöiden ja ryhmien erilaiset elämänkokemukset, elämänvaiheet sekä erilaiset sosiaaliset ja taloudelliset tilanteet ohjausprosessien lähtökohtana",
      "ohjauksen palvelujen järjestämiseen ja tuottamiseen vaikuttavat tekijät, reunaehdot ja resurssit",
      "ohjauksen järjestämisen taustalla olevat keskeisimmät sidosryhmät",
      "ohjauksen aseman osana koulutus-, elinkeino-, nuoriso- sekä sosiaali- ja terveyspolitiikkaa",
      "eri hallinnonalojen yhteiset tavoitteet ja ohjauksen järjestämistä koskevat linjaukset",
      "erilaiset palvelukanavat ohjauksen toteuttamiselle",
    ],
    osaa: [
      "tarkastella ohjausta osana yksilön ja ryhmien elämänprosesseja",
      "tarkastella ohjausta tavoitteellisina vuorovaikutustilanteina",
      "tarkastella ohjaukseen liittyviä tapahtumia siinä kontekstissa, jossa ne esiintyvät",
      "tarkastella ohjausta yksilön subjektiivisena oikeutena",
      "tehdä ohjaustyötä ja tuottaa ohjauspalveluja monikanavaisesti yksilöllisissä ja yhteisöllisissä prosesseissa, jotka vastaavat asiakkaiden tarpeita",
    ],
  },
  "B1.2": {
    id: "B1.2",
    header: "Ohjaus prosessina",
    text: "Ohjaaja ottaa huomioon ohjauksen tieto-, vuorovaikutus- ja prosessiulottuvuudet. Tietoulottuvuus koskee yksilön tietoa omasta itsestä, toimintaympäristön mahdollisuuksista sekä yksilön suhteesta omaan toimintaympäristöönsä. Vuorovaikutusulottuvuus liittyy ohjaussuhteen rakentamiseen ja ylläpitämiseen. Prosessiulottuvuus kattaa ymmärryksen tavasta tehdä ratkaisuja sekä ratkaisujen taustalla vaikuttavien tekijöiden yhteyksistä tehtyihin ratkaisuihin.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen prosessiluonteen asiakkaiden itsenäisessä työskentelyssä, ohjaajan avustamassa työskentelyssä, ohjaajan fasilitoimassa yksilö- ja ryhmäohjauksessa sekä yhteistoiminnallisissa prosesseissa eri konteksteissa",
      "ohjauksen sisältö-, vuorovaikutus- ja prosessiulottuvuuden merkityksen",
      "asiakkaan ja ryhmien yksilöllisen tilanteen, valmiudet ja tarpeet kokonaisuudessaan ohjauksen lähtökohtana",
      "eron yksittäisten ohjaustilanteiden ja pitkäkestoisten ohjausprosessien välillä",
      "ohjausprosessin kokonaisrakenteen merkityksen ja prosessin etenemisen eri vaiheet",
      "ohjaajan ja asiakkaan välisten valtasuhteiden eroavaisuudet sekä ohjaajan roolin vaihtelun erilaisissa ohjauksen työmuodoissa",
    ],
    osaa: [
      "arvioida yhdessä asiakkaan ja ryhmien kanssa heidän tilanteensa ja palvelutarpeensa",
      "arvioida asiakkaan lähtötilanteen sekä valita asiakkaan ja ryhmien tilanteeseen, kontekstiin ja palvelutarpeeseen perustuvan tarkoituksenmukaisen lähestymistavan ja työmuodon",
      "jäsentää ohjauksen kuluessa asiakkaan tilanteen ja toimintamahdollisuudet ymmärrettäväksi kokonaisuudeksi",
      "suunnitella, toteuttaa, arvioida, johtaa, kehittää ja uudistaa ohjausprosesseja huomioiden kontekstin, eri asiakasryhmien moninaisuuden ja taustan ohjauksen voimavarana",
      "sopia asiakkaan ja ryhmien kanssa prosessista, jossa määritellään ohjauksen tavoitteet ja rajat",
      "arvioida yhdessä asiakkaan ja ryhmien kanssa, onko ohjausprosessi tarkoituksenmukainen suhteessa asiakkaan tai ryhmien tilanteeseen, kontekstiin ja palvelutarpeeseen",
      "työskennellä asiakkaan ja ryhmien kanssa yhteisen merkityksen ja yhteisten työskentelytapojen luomiseksi ja tavoitteiden saavuttamiseksi",
    ],
    context_competencies: [
      {
        context: "Perusopetus",
        tunnistaa_ja_ymmartaa: [
          "perusopetuksen ja lukion opetussuunnitelmien ja ammatillisten tutkintojen perusteissa ohjaukselle asetetut tavoitteet sekä ohjauksen osa-alueet ja toimintamallit",
          "koulutuksen järjestäjän ja koulun ohjaussuunnitelmassa kuvatut oppilaanohjauksen tavoitteet ja toimintamallit",
          "oppilaanohjauksen liittymäpinnat opiskeluhuoltoon ja koulun muuhun toimintaan",
        ],
        osaa: [
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään erilaisia toimintamalleja, kuten esim. luokka-, ryhmä- ja henkilökohtaista ohjausta, työelämään tutustumista, vierailuja sekä moniammatillista ja monialaista yhteistyötä",
          "suunnitella ja toteuttaa tilanteita, joissa oppilaat oppivat itsearviointitaitoja",
          "arvioida oppilaan ohjauksen ja tuen tarpeita hyödyntämällä erilaisia ohjauksellisia menetelmiä ja työvälineitä",
        ],
      },
      {
        context: "Lukiokoulutus",
        tunnistaa_ja_ymmartaa: [
          "perusopetuksen ja lukion opetussuunnitelmien ja ammatillisten tutkintojen perusteissa ohjaukselle asetetut tavoitteet sekä ohjauksen osa-alueet ja toimintamallit",
          "koulutuksen järjestäjän ja lukion ohjaussuunnitelmassa kuvatut opinto-ohjauksen tavoitteet ja toimintamallit",
          "opinto-ohjauksen liittymäpinnat opiskeluhuoltoon ja lukion muuhun toimintaan",
        ],
        osaa: [
          "rakentaa ohjausprosessista tavoitteellisen kokonaisuuden, joka alkaa koulutukseen hakeutumisvaiheesta ja jatkuu tutkinnon suorittamiseen saakka sekä tarvittaessa opintojen päättymisen jälkeen jatko-ohjauksena",
          "järjestää ohjauksellista tukea opiskelijoille, jotka tarvitsevat yksilöllisiä ratkaisuja siirtyessään lukio- tai ammatilliseen koulutukseen",
          "tehdä yhteistyötä ryhmänohjaajien kanssa lukion ohjauksen tavoitteiden saavuttamiseksi",
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään erilaisia toimintamalleja, kuten esim. opiskelijoiden laatimaa opintosuunnitelmaa, luokka-, ryhmä- ja henkilökohtaista ohjausta, työelämään tutustumista, vierailuja sekä moniammatillista ja monialaista yhteistyötä",
          "suunnitella ja toteuttaa tilanteita, joissa opiskelijat oppivat itsearviointitaitoja",
          "arvioida opiskelijoiden ohjauksen ja tuen tarpeita hyödyntämällä erilaisia ohjauksellisia menetelmiä ja työvälineitä",
        ],
      },
      {
        context: "Ammatillinen koulutus",
        tunnistaa_ja_ymmartaa: [
          "perusopetuksen ja lukion opetussuunnitelmien ja ammatillisten tutkintojen perusteissa ohjaukselle asetetut tavoitteet sekä ohjauksen osa-alueet ja toimintamallit",
          "koulutuksen järjestäjän ja oppilaitoksen dokumenteissa kuvatut opinto-ohjauksen tavoitteet ja toimintamallit",
          "opinto-ohjauksen liittymäpinnat opiskeluhuoltoon ja oppilaitoksen muuhun toimintaan",
        ],
        osaa: [
          "rakentaa ohjausprosessista tavoitteellisen kokonaisuuden, joka alkaa koulutukseen hakeutumisvaiheesta tutkinnon suorittamiseen saakka",
          "tehdä yhteistyötä oppilaitoksen opettajien ja muiden toimijoiden kanssa ohjauksen tavoitteiden saavuttamiseksi",
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään erilaisia toimintamalleja, kuten esim. opiskelijan henkilökohtaista osaamisen kehittämissuunnitelmaa, luokka-, ryhmä- ja henkilökohtaista ohjausta, työelämässä tapahtuva oppimista, vierailuja sekä moniammatillista ja monialaista yhteistyötä",
          "järjestää ohjauksellista tukea opiskelijoille, jotka tarvitsevat yksilöllisiä ratkaisuja siirtyessään lukio- tai ammatilliseen koulutukseen",
          "suunnitella ja toteuttaa tilanteita, joissa opiskelijat oppivat itsearviointitaitoja",
          "arvioida opiskelijoiden ohjauksen ja tuen tarpeita hyödyntämällä erilaisia ohjauksellisia menetelmiä ja työvälineitä",
        ],
      },
      {
        context: "Korkea-aste",
        tunnistaa_ja_ymmartaa: [
          "opiskelijoiden ohjaukselle oppilaitoksessa asetetut tavoitteet ja sisällöt sekä työnjaon",
          "ohjauksen liittymäpinnat muihin opiskelijoiden tukipalveluihin",
        ],
        osaa: [
          "rakentaa ohjausprosessista tavoitteellisen kokonaisuuden, joka alkaa koulutukseen hakeutumisvaiheesta tutkinnon suorittamiseen saakka",
          "tehdä yhteistyötä opettajatuutoreiden ja muun henkilöstön kanssa ohjauksen tavoitteiden saavuttamiseksi",
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään erilaisia toimintamalleja, kuten esim. opiskelijan henkilökohtaista opiskelusuunnitelmaa, ryhmä- ja henkilökohtaista ohjausta, työelämässä tapahtuva oppimista, uraohjausta, vierailuja sekä moniammatillista ja monialaista yhteistyötä",
          "suunnitella ja toteuttaa tilanteita, joissa opiskelijat oppivat itsearviointitaitoja",
          "arvioida opiskelijoiden ohjauksen ja tuen tarpeita hyödyntämällä erilaisia ohjauksellisia menetelmiä ja työvälineitä",
        ],
      },
      {
        context: "Työvoimapalvelut",
        tunnistaa_ja_ymmartaa: [
          "työvoimapalvelujen kohderyhmät ja kokonaistarjonnan",
          "asiakkaiden terveydellisten tekijöiden tai rajoitteiden yhteyden työkykyyn ja työllistyvyyteen",
          "asiakkaiden saatavilla olevat tukimuodot ja palvelutarjonnan",
        ],
        osaa: [
          "tehdä yhteistyötä muun henkilöstön kanssa ohjauksen tavoitteiden saavuttamiseksi",
          "tehdä yhteistyötä ostopalveluna toteutettavien tukipalvelujen kanssa",
          "hyödyntää olemassa olevia työvälineitä asiakkaiden tilanteiden, palvelutarpeiden ja soveltuvuuden arviointiin",
          "tunnistaa asiakkaiden valmiuksia sekä järjestää tarvittaessa työkyvyn tutkimuksia tai muita asiantuntija-arviointeja työ-, yritystoiminta- ja koulutusvaihtoehtojen selvittämiseksi sekä urasuunnitelmien tarkentamiseksi",
          "tunnistaa psykologisia ja muita työllistymiseen liittyviä esteitä sekä kykenee työskentelemään niiden parissa tavoitteellisesti",
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään erilaisia toimintamalleja, kuten esim. asiakkaiden työllistymissuunnitelmia, työhönvalmennuksia, työnhaku- ja uravalmennuksia, uraohjausta sekä työ- ja koulutuskokeiluja sekä moniammatillista ja monialaista yhteistyötä",
          "ohjata asiakkaita tunnistamaan ja sanoittamaan osaamistaan työllistyvyytensä edistämiseksi",
          "käyttää asiakkaan suostumuksella psykologisia arviointimenetelmiä ja tehtäviä osana ohjausprosessia (koskee vain laillistettuja psykologeja)",
          "ohjata osatyökykyisiä työllistymisessä",
          "neuvoa asiakkaita tarvittaessa saatavilla olevasta palvelutarjonnasta ja tukimuodoista",
        ],
      },
      {
        context: "Ohjaamot",
        tunnistaa_ja_ymmartaa: [
          "Ohjaamojen asiakasryhmät ja kokonaistarjonnan",
          "asiakkaiden saatavilla olevat tukimuodot ja palvelutarjonnan",
        ],
        osaa: [
          "rakentaa ohjausprosessista kokonaisuuden, jossa hyödynnetään monialaista yhteistyötä",
          "tehdä yhteistyötä oppilaitosten opettajien ja muiden toimijoiden kanssa ohjauksen tavoitteiden saavuttamiseksi",
          "neuvoa asiakkaita tarvittaessa saatavilla olevasta palvelutarjonnasta ja tukimuodoista",
        ],
      },
    ],
  },
  "B1.3": {
    id: "B1.3",
    header: "Ohjausprosessin tulos ja vaikuttavuus",
    text: "Ohjaaja arvioi yhdessä asiakkaan kanssa systemaattisesti ohjausprosessin tulosta ja vaikuttavuutta. Arvioinnin kohteena ovat prosessi sekä saavutetut tulokset, taidot ja havaitut muutokset asiakkaan tilanteessa. Tulosten ja vaikuttavuuden arviointi edellyttää ohjausprosessin jatkuvaa arviointia sen suhteen, miten prosessi etenee, miten ohjaaja sitoutuu palvelun tuottamiseen ja kehittämiseen, miten ohjaus vastaa käyttäjäryhmien tarpeita, miten asiakkaat osallistuvat prosessiin ja sen suunnitteluun, miten toimivia ovat ohjaussuhteet ja käytetyt toimintamallit sekä miten arviointitietoa hyödynnetään palveluiden kehittämisessä.",
    tunnistaa_ja_ymmartaa: [
      "yksittäisten ohjausprosessien tuloksen ja vaikuttavuuden arvioinnin merkityksen oman työn kehittämisen näkökulmasta",
      "ohjausprosessien tuloksen ja vaikuttavuuden arvioinnin merkityksen yksilön näkökulmasta",
      "ohjausprosessin tuloksiin vaikuttavat tekijät, kuten kontekstin, asiakkaan elämäntilanteen, sosiaaliset verkostot ja saatavilla olevat palvelut",
      "menetelmät ja kriteerit ohjausprosessin tulosten ja vaikuttavuuden arvioimiseksi",
      "miten määritellään arviointikohteet ja -kriteerit ohjausprosessien tuloksen ja vaikuttavuuden todentamiseksi",
      "ohjausprosessin tuloksia, asiakkaan taitojen kehittymistä ja tilanteen muutoksia",
    ],
    osaa: [
      "määrittää yhdessä asiakkaiden/ryhmien kanssa ohjausprosessin tavoitteet ja seurata niiden toteutumista",
      "kerätä asiakkailta/ryhmiltä palautetta ohjausprosessin aikana ja sen päättyessä",
      "ohjata asiakkaita/ryhmiä arvioimaan omaa kehittymistään ja omien taitojen ja osaamisen kehittymistä prosessin aikana",
      "tarkentaa tai määrittää uudelleen ohjauksen tavoitteita palautteen ja arviointitiedon pohjalta",
      "kehittää toimintaansa ohjaajana saatujen tulosten ja palautteen perusteella",
      "analysoida ja arvioida ohjausprosesseja ja -menetelmiä, omia ammattikäytänteitä sekä niiden laatua ja vaikuttavuutta",
    ],
  },
  "B2.1": {
    id: "B2.1",
    header: "Kehitys- ja elämänkulkuteoriat",
    text: "Ohjaaja tarkastelee ohjausta yksilön näkökulmasta erikseen kehitysprosessina, elämäntilanteena ja sen kontekstina.",
    tunnistaa_ja_ymmartaa: [
      "yksilön kehitystä ja elämänkulkua käsittelevien teorioiden sidonnaisuuden kontekstiin ja aikakauteen, jolloin ne on kehitetty",
      "miten yksilön kehitykseen ja elämänkulkuun liittyvää teoriatietoa sovelletaan ohjausprosessissa",
      "kontekstin, asiakkaan elämäntilanteen, ihmissuhteiden ja yksilöllisten tekijöiden vaikutuksen ohjausprosessissa ja tavoitteiden saavuttamisessa",
      "osallisuuteen ja syrjäytymiseen vaikuttavien tekijöiden tietoperustan omassa työssään",
      "hyvinvointiin, toimintakykyyn ja toimijuuteen vaikuttavia tekijöitä",
      "tekijöitä, jotka vaikuttavat emotionaaliseen, sosiaaliseen, kulttuuriseen ja kognitiiviseen kehitykseen",
      "oppimiseen ja oman osaamisen kehittämiseen vaikuttavia tekijöitä",
    ],
    osaa: [
      "valita tilanteeseen soveltuvan teoreettisen lähestymistavan",
      "soveltaa yksilön kehitykseen, elämänkulkuun ja kontekstiin liittyviä keskeisiä teorioita ohjaustyössä",
      "ottaa huomioon yksilön kehitysvaiheet sekä yksilölliset erot psyykkisessä, fyysisessä ja sosiaalisessa kehityksessä",
      "soveltaa osallisuutta ja syrjäytymistä käsittelevää tutkimus- ja teoriatietoa ohjaustyössä",
      "hyödyntää yksilön oppimista, käyttäytymistä, identiteettiä sekä fyysistä, psyykkistä ja sosiaalista hyvinvointia ja toimijuutta käsittelevää tutkimus- ja teoriatietoa ohjaustyössä",
    ],
  },
  "B2.2": {
    id: "B2.2",
    header: "Urateoriat",
    text: "Ohjaaja tarkastelee urakehitystä yksilön ominaisuuksien ja kiinnostusten sekä arvojen viitekehyksessä. Urakehitystä voidaan kuvata myös prosessina, johon vaikuttavat historiallinen aika, talous ja yleensä yhteiskunnassa vallitsevat olosuhteet sekä yksilön elämänpiiri ja ihmissuhteet.",
    tunnistaa_ja_ymmartaa: [
      "urateorioiden aika- ja kontekstisidonnaisuuden",
      "keskeiset urateoriat",
      "urakehityksen yhteyden ihmisen kehitys- ja elämänkulkuun",
      "urakehitykseen vaikuttavia tekijöitä yksilön sosiaalisissa suhteissa, elämäntilanteessa ja verkostoissa",
      "urasuunnittelutaitojen osa-alueet ja niiden kehittymisen merkityksen asiakkaan elämän- ja urasuunnittelussa",
      "siirtymien ja siirtymävaiheiden moninaisuuden ja merkityksen yksilön elämänkulussa",
      "työllistyvyyteen ja työllistymiseen vaikuttavia tekijöitä",
    ],
    osaa: [
      "tarkastella kriittisesti eri urateorioiden lähestymistapoja ja soveltuvuutta ohjausprosessiin",
      "soveltaa yksilön tai ryhmän tarpeisiin soveltuvia urateorioita",
      "huomioida urasuunnitteluun liittyvät kontekstuaaliset, relationaaliset ja yksilölliset tekijät ohjausprosessin aikana",
      "ohjata asiakkaita urasuunnittelutaitojen kehittämisessä",
      "ohjata asiakkaita siirtymävaiheissa",
      "ohjata asiakkaita työllistymiseen liittyvien taitojen kehittämisessä",
    ],
  },
  "B2.3": {
    id: "B2.3",
    header: "Yhteiskunta ja työelämä",
    text: "Ohjaaja tarkastelee ohjaustyötä osana yhteiskunnallista ja kulttuurista todellisuutta, joka vaikuttaa asiakkaiden tilanteeseen ja mahdollisuuteen rakentaa elämäänsä ja uraansa yhdenvertaisesti ja kestävästi. Yksilön omien tavoitteiden ja ohjaustarpeiden rinnalla ohjauksen järjestämiseen vaikuttavat yhteiskunnalliset, terveys- ja kuntoutuspoliittiset sekä koulutus- ja työvoimapoliittiset tavoitteet. Koska työelämä ja yksilöt kehittyvät jatkuvasti, ohjauksessa tarvitaan dynaamista käsitystä yhteiskunnasta ja ihmisen mahdollisuuksista oppia tämän muutoksen mukana.",
    tunnistaa_ja_ymmartaa: [
      "yhteiskunnan ja työmarkkinoiden keskeisimmät toimintaperiaatteet",
      "yhteiskunnan rakenteiden ja toimintajärjestelmien vaikutuksen omassa työssään",
      "globaalien taloudellisten tekijöiden vaikutukset työmarkkinoihin",
      "ennakointitiedon ja tulevaisuuslukutaidon merkityksen omassa työssään",
      "erilaisia muutoksia ennakoivia signaaleja",
      "toimintaympäristössään tapahtuvien muutosten vaikutukset omassa työssään",
      "asiakkaiden intressien ja työmarkkinoiden liittymäpinnan",
      "koulutusjärjestelmän yhteiskunnalliset funktiot ja ohjauksen paikan koulutuksen kokonaisrakenteessa",
      "ajankohtaisten kansainvälisten ja kansallisten trendien vaikutuksen asiakkaiden ja organisaatioiden toimintakykyyn sekä elämän- ja urasuunnitteluun",
      "ohjauksen merkityksen yhdenvertaisuuden ja tasa-arvon toteutumisessa yhteiskunnassa",
      "ohjauksen merkityksen osallisuuden ja kestävän elämäntavan toteutumisessa yhteiskunnassa",
    ],
    osaa: [
      "tarkastella kriittisesti yhteiskunnallisen kehityksen suuntia",
      "tarkastella taloudellisten ja muiden ihmisten elämään vaikuttavien tekijöiden merkitystä asiakkaiden elämän- ja urasuunnittelussa",
      "tarkastella ihmisten välisiä yhteiskunnallisesti rakentuneita eroja ja valtasuhteita koulutuksessa ja työelämässä",
      "ottaa huomioon työelämässä tapahtuvien muutosten vaikutukset yksilöiden työuriin, osaamisvaatimuksiin ja liikkuvuuteen",
      "hyödyntää ennakointitietoa ja tulevaisuuslukutaitoa omassa työssään",
      "edistää yhdenvertaisuuden ja tasa-arvon toteutumista",
      "huomioida kestävää elämäntapaa vahvistavia tekijöitä ohjauksessa",
      "ohjata asiakkaita, kun he suunnittelevat koulutus- ja työuraa kansainvälisissä konteksteissa",
    ],
  },
  "B2.4": {
    id: "B2.4",
    header: "Ohjausteoriat",
    text: "Ohjaaja soveltaa ohjausteorioita ottaen huomioon yksilön ja hänen kontekstinsa asiakaslähtöisesti. Ohjausteoreettinen osaaminen antaa ohjaajalle perustan rakentaa tavoitteellisia mahdollistamis- ja oppimisprosesseja. Lisäksi teoreettisen osaamisen kautta ohjaaja jäsentää ohjausta vuorovaikutussuhteena sekä erilaisissa ihmissuhdeammateissa käytettävinä työmenetelminä ja dialogina.",
    tunnistaa_ja_ymmartaa: [
      "ohjaustyössä käyttämiensä menetelmien ja työvälineiden taustateoriat sekä niiden aika- ja kontekstisidonnaisuuden",
      "ohjaussuhteen rakentamista ja ylläpitoa jäsentäviä lähestymistapoja",
      "lähestymistapoja ja menetelmiä, joiden avulla asiakkaat voivat vahvistaa toimijuuttaan, hyvinvointiaan ja urasuunnittelutaitojaan.",
      "yksilöohjausta jäsentävät teoriat",
      "ryhmäytymistä, ryhmän jäsenyyttä ja ryhmien toimintaa jäsentävät teoriat",
      "asiakkaiden valmiuksien ja ohjaustarpeiden kartoittamisen merkityksen ohjausprosessin vaikuttavuuden näkökulmasta",
      "asiakkaan urasuunnittelutaitoihin vaikuttavat yksilölliset, relationaaliset ja kontekstuaaliset tekijät",
      "asiakkaan taustalla vaikuttavien kulttuuristen tekijöiden merkityksen urasuunnittelussa ja ohjausprosessissa",
    ],
    osaa: [
      "kuvata ja perustella, miten hän suunnittelee, toteuttaa ja arvioi ohjausta ja ohjausprosesseja ohjausteorioita soveltaen",
      "arvioida valitsemansa käyttöteorian sopivuutta suhteessa asiakkaan tarpeisiin ja tilanteeseen",
      "arvioida käyttöteoriansa soveltuvuutta kulloiseenkin ohjausprosessiin",
      "ohjata asiakasta urasuunnittelutaitojen kehittämisessä hyödyntämällä erilaisia ohjauksellisia menetelmiä ja työvälineitä monipuolisesti",
      "ohjata asiakasta työllistymisessä ja työnhaun eri vaiheissa hyödyntämällä erilaisia menetelmiä ja työvälineitä monipuolisesti",
    ],
  },
  "B2.5": {
    id: "B2.5",
    header: "Elinikäinen ja jatkuva oppiminen",
    text: "Ohjaaja varmistaa, että urasuunnitteluun liittyvässä oppimisprosessissa asiakkaat ja ryhmät oppivat arvioimaan ja kehittämään vahvuuksiaan sekä osaamistaan suhteessa toimintaympäristön suomiin mahdollisuuksiin. Ohjausprosessissa nostetaan esille laaja-alaisia taitoja, joiden avulla yksilöt havainnoivat muutoksia omassa toimintaympäristössään, täsmentävät itselleen oppimisen kohteita, kehittävät resilienssiään sekä tuottavat aktiivisesti muutosta ja kehitystä omassa elämässään ja urallaan.",
    tunnistaa_ja_ymmartaa: [
      "elinikäisen osaamisen kehittämisen ja jatkuvan oppimisen merkityksen yksilön elämänkulussa",
      "urasuunnittelun elinikäisenä jatkumona ja oppimisprosessina",
      "urasuunnittelutaidot itsenäisenä, opittavissa olevana laaja-alaisena osaamisena",
      "urasuunnittelutaitojen merkityksen erilaisissa elämäntilanteissa ja siirtymävaiheissa",
      "keskeisintä oppimiseen liittyvää teoriatietoa ja tutkimusta",
      "osaamisen tunnistamiseen liittyvät tekijät ja toimintamallit",
      "asiakkaiden oppimiskykyyn, resilienssiin ja hyvinvointiin vaikuttavia tekijöitä, neuropsykiatrisia haasteita sekä niiden vaikutuksia oppimiseen, osaamisen kehittämiseen, urasuunnitteluun ja suunnitelmien toteuttamiseen",
      "tunnistaa asiakkaan aiempien opiskelukokemusten vaikutuksen urasuunnitteluun ja suunnitelmien toteuttamiseen",
      "yleisten työelämätaitojen merkityksen urasuunnittelussa ja työllistymisessä",
    ],
    osaa: [
      "soveltaa toiminnallisia ja ongelmalähtöisiä työtapoja ohjaustilanteissa",
      "luoda toiminnallisia ja ongelmalähtöisiä oppimistilanteita, joiden avulla asiakas voi kehittää urasuunnittelutaitojaan",
      "ohjata asiakkaita itsearviointitaitojen kehittämisessä siten, että he pystyvät kuvaamaan vahvuuksiaan, osaamistaan ja kehittymisen kohteitaan",
      "ohjata asiakasta opiskelu- ja itsesäätelytaitojen kehittämisessä",
      "ohjata asiakkaita digitaalisen teknologian käytön ja tiedonhakutaitojen kehittämisessä",
      "tunnistaa yleisiä oppimiseen ja opiskelutaitoihin liittyviä ongelmia ja ohjata asiakkaan tarvittaessa oppimisen tuen palveluihin",
      "ohjata asiakkaita toimimaan suunnitelmiensa ja tavoitteidensa saavuttamiseksi",
      "ohjata asiakkaita itsereflektointi- ja -arviointitaitojen kehittämisessä, jotta he pystyvät analysoimaan omaa oppimisprosessiaan ja omien laaja-alaisten taitojensa kehittymistä",
    ],
  },
  "B2.6": {
    id: "B2.6",
    header: "Ohjausfilosofia",
    text: "Ohjaaja tarkastelee omaa ja asiakkaan maailmankatsomusta todellisuuden tulkinnan käsitteellistämisenä, tiedostamisena ja jäsentämisenä. Tavoitteena on yksilön itsetuntemuksen vahvistuminen sekä elämän perusteiden ja uskomusten jäsentäminen. Ohjausfilosofiset kysymykset linkittyvät ohjauksen eettisiin periaatteisiin.",
    tunnistaa_ja_ymmartaa: [
      "ohjausfilosofisten näkökulmien yhteydet omaan ohjausorientaatioon",
      "erilaisia maailmankatsomuksellisia ulottuvuuksia ja sisältöjä ",
      "ohjausfilosofisten kysymysten ja eettisten periaatteiden yhteyden",
    ],
    osaa: [
      "määritellä oman maailmankatsomuksensa ja ihmiskäsityksensä sekä niiden vaikutuksen omaan ohjausorientaatioon",
      "kunnioittaa asiakkaan maailmankatsomusta ja arvomaailmaa",
      "ohjata asiakasta hänen arvoperustansa määrittelyssä ja sitä kautta itsetuntemuksen kehittämisessä",
      "tarkastella kriittisesti maailmaa ja tulevaisuutta sekä yhteiskunnassa vallitsevia arvoja",
      "ohjata asiakasta kriittisen arviointitaidon kehittämisessä",
    ],
  },
  "B3.1": {
    id: "B3.1",
    header: "Tiedonhankinta ja -hallinta",
    text: "Ohjaaja varmistaa, että hänellä itsellään sekä asiakkailla ja ryhmillä on käytettävissä ajantasaiset tietolähteet elämää, opiskelua ja työtä koskevien suunnitelmien ja ratkaisujen taustaksi. Ohjaaja varmistaa, että asiakkaat ja ryhmät kehittävät tarvittavat taidot tiedon hankkimiseksi, arvioimiseksi ja hyödyntämiseksi tehdessään omaa elämäänsä koskevia suunnitelmia ja valintoja.",
    tunnistaa_ja_ymmartaa: [
      "ajantasaiset ja luotettavat tietolähteet, joita hän voi hyödyntää omassa työssään",
      "laatukriteerit, joiden perusteella hän arvioi käyttämänsä tiedon ja tietolähteiden luotettavuutta",
      "ajantasaisen työmarkkinatiedon merkityksen ohjauksessa",
      "tietolähteet, joista löytyy ajantasaista informaatiota koulutus- ja työllistymisvaihtoehdoista",
      "tietolähteet yrittäjyydestä",
      "tietolähteet tarjolla olevista tukimuodoista",
      "olennaiset tietolähteet tarjolla olevista muista palveluista",
    ],
    osaa: [
      "valita ajantasaiset, luotettavat ja soveltuvat materiaalit",
      "hakea tietoa koulutusmahdollisuuksista ja niihin liittyvistä valintamenettelyistä",
      "hakea tietoa työllistymisvaihtoehdoista ja erilaisista työn tekemisen muodoista sekä työhön hakeutumisen toimintamalleista",
      "hakea tietoa yrittäjyydestä",
      "hakea tietoa olemassa olevista asiakkaille tarjolla olevista tukimuodoista",
      "ohjata asiakasta tiedonhaussa, tiedon arvioinnissa ja käytössä",
    ],
  },
  "B3.2": {
    id: "B3.2",
    header: "Tavoitteiden tarkastelu ja asettaminen",
    text: "Ohjaaja rakentaa luottamuksellisen ohjaussuhteen. Ohjaaja kartoittaa asiakkaan tai ryhmän valmiudet ja ohjaustarpeet sekä asettaa ohjausprosessin tavoitteet yhdessä asiakkaan tai ryhmän jäsenten kanssa.",
    tunnistaa_ja_ymmartaa: [
      "ohjausprosessin alkuvaiheessa tapahtuvan ohjaussuhteen rakentamisen ja ohjaustarpeen kartoituksen merkityksen",
      "urasuunnittelutaitojen moniulotteisuuden",
      "ohjaustilanteeseen vaikuttavat asiakkaan henkilökohtaiset tavoitteet sekä oman organisaation perustehtävään liittyvät ohjauksen järjestämistä koskevat ohjeet ja tavoitteet",
      "organisaatiossa käytössä olevat ohjeet ja dokumentit asiakkaan yksilöllisen kehittymissuunnitelman laatimiseksi",
      "moniammatillisen ja monialaisen yhteistyön merkityksen ohjausprosessin aikana",
    ],
    osaa: [
      "yksilöidä erikseen asiakkaan henkilökohtaiset tavoitteet sekä kaikkia asiakasryhmiä koskevat organisaation perustehtävään liittyvät ohjaukselliset ja hallinnolliset tavoitteet",
      "arvioida asiakkaan tai ryhmän urasuunnittelutaitojen osaamisen tason ja kehittämisen kohteet",
      "hyödyntää erilaisia työvälineitä ja menetelmiä asiakkaiden ja ryhmien valmiuksien ja ohjaustarpeiden kartoittamisessa",
      "rakentaa luottamuksellisen ohjaussuhteen",
      "ohjata asiakasta tai ryhmää itsetuntemuksen ja itsearviointitaitojen kehittämisessä",
      "ohjata asiakasta osaamisensa tunnistamisessa ja sanoittamisessa",
      "asettaa tavoitteet yhdessä asiakkaan tai ryhmän kanssa ohjausprosessille",
      "neuvotella asiakkaiden ja ryhmien kanssa ohjausprosessin aikana käytettävistä työmuodoista, menetelmistä ja palvelukanavista",
      "tehdä tarvittaessa moniammatillista ja monialaista yhteistyötä",
      "tunnistaa ja sanoittaa ammattinsa ja osaamisensa rajat",
    ],
  },
  "B3.3": {
    id: "B3.3",
    header: "Asiakaslähtöinen ohjausprosessi",
    text: "Ohjaaja suunnittelee ja toteuttaa yhdessä asiakkaiden kanssa ohjausprosessin. Ohjausprosessin tavoitteiden ja toimintamallien lähtökohtana ovat asiakkaan tai ryhmän valmiudet ja tarpeet. Ohjaaja seuraa ja arvioi, miten ohjausprosessi etenee asiakkaan tai ryhmän kanssa yhdessä sovittujen toimintamallien ja asetettujen tavoitteiden mukaisesti. Ohjaaja seuraa ja arvioi asetettujen tavoitteiden saavuttamista yhdessä asiakkaiden kanssa.",
    tunnistaa_ja_ymmartaa: [
      "asiakkaiden ja ryhmien yksilölliset valmiudet ja lähtökohdat oman tulevaisuuden ja uran suunnitteluun",
      "urasuunnitteluun ja päätöksentekoon liittyvän prosessin luonteen, sen vaiheet ja niihin liittyvät tekijät",
      "asiakaslähtöisyyden ohjauksessa",
      "ohjausprosessille asetettujen tavoitteiden ja niiden toteutumisen etenemisen seurannan ja arvioinnin merkityksen",
      "monipuolisten ja tarkoituksenmukaisten interventioiden, aktiviteettien ja menetelmien hyödyntämisen merkityksen",
      "palautteen keräämisen merkityksen ohjausprosessin päättyessä",
    ],
    osaa: [
      "ohjata asiakasta tai ryhmää soveltaen tarkoituksenmukaisia menetelmiä, työvälineitä ja palvelukanavia",
      "dokumentoida ohjausprosessin etenemistä",
      "soveltaa ura- ja ohjausteorioita tarkoituksenmukaisesti ohjausprosessin aikana",
      "arvioida, miten ohjausprosessi etenee tavoitteiden saavuttamiseksi",
      "arvioida, onko asetettuja tavoitteita syytä tarkistaa ohjausprosessin aikana",
      "valita työmuotoja, joissa asiakkaat ovat aktiivisia toimijoita",
      "valita interventioita ja työvälineitä, joiden avulla asiakkaat ja ryhmät vahvistavat toimijuuttaan ja taitojaan itsenäiseen työskentelyyn",
      "tehdä tarvittaessa yhteistyötä oppilaitosten, työelämän ja muiden tahojen ja sidosryhmien kanssa",
      "varmistaa, että käytetyt materiaalit ja tietolähteet ovat ajantasaisia ja luotettavia",
      "ohjata asiakasta ja ryhmiä laajentamaan näkökulmiaan ja käsityksiään olemassa olevista vaihtoehdoista",
      "ohjata asiakkaita ja ryhmiä arvioimaan eri vaihtoehtojen soveltuvuutta itselleen",
      "ohjata asiakkaita ja ryhmiä kehittämään päätöksentekotaitojaan",
      "ohjata asiakkaita ja ryhmiä toteuttamaan suunnitelmiaan ja tarvittavia konkreettisia toimenpiteitä",
      "ohjata asiakkaita ja ryhmiä kehittämään siirtymissä ja siirtymävaiheissa tarvittavia taitoja",
      "päättää ohjausprosessin ja kerätä palautetta asiakkaan tai ryhmän kokemuksista prosessin aikana",
      "kehittää omia työtapojaan palautteen ja arviointitiedon perusteella",
    ],
  },
  "B3.4": {
    id: "B3.4",
    header: "Arviointi",
    text: "Ohjaaja arvioi yhdessä asiakkaan kanssa ohjausprosessin tulosta ja vaikuttavuutta. Arviointi kattaa oppimistulokset ja ohjauksen kautta kehittyneet urasuunnittelutaidot, muutokset toimintakyvyssä, hyvinvoinnissa, toimijuudessa ja osaamisessa sekä omassa elämäntilanteessa ja urakehityksessä. Lisäksi tarkastellaan, mitä yksilö on oppinut ohjausprosessista ja miten hän hyödyntää tätä osaamista jatkossa.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen laadulliset ja määrälliset tulokset sekä vaikuttavuuden yksilön kannalta",
      "ohjausprosessin dokumentoinnin periaatteet",
      "ohjausprosessin ja sille asetettujen tavoitteiden ja niiden toteutumisen arvioinnin merkityksen",
      "yksittäisten ohjausprosessien tulosten arvioinnin osana oman organisaation toiminnan vaikuttavuuden ja laadun arviointia",
    ],
    osaa: [
      "yksilöidä ohjausprosessin laadulliset ja määrälliset tulokset ja vaikuttavuuden sekä niiden osoittamiseen tarvittavat arviointikohteet, tietolähteet ja kriteerit",
      "dokumentoida ohjausprosessin etenemistä ja hyödyntää dokumentaatiota ohjausprosessin arvioinnin taustamateriaalina",
      "käyttää tarvittavia laadullisia ja määrällisiä työmuotoja ja -menetelmiä yksittäisen ohjausprosessin tuloksen ja vaikuttavuuden arvioimiseksi ",
      "yksilöidä, miten asiakkaat ja ryhmät ovat saavuttaneet asettamansa tavoitteet",
      "ohjata asiakkaita ja ryhmiä arvioimaan ohjausprosessin aikana kokemaansa muutosta ja tuloksia sekä kehittämiään urasuunnittelutaitojaan",
      "kehittää omia työtapojaan arviointitiedon perusteella",
    ],
  },
  "C1.1": {
    id: "C1.1",
    header: "Ohjaus yhteiskunnassa",
    text: "Ohjaaja tunnistaa koulutuksen, työelämän ja yhteiskunnan välisiä ilmiöitä, suhteita, trendejä ja muutoksia sekä hyödyntää työssään tulevaisuutta koskevaa ennakointitietoa. Lisäksi hänellä on taitoa edistää rakenteita tiedostavaa ohjausta ja kestävää, yhdenvertaista, oikeudenmukaista sekä monimuotoista yhteiskuntaa.",
    tunnistaa_ja_ymmartaa: [
      "koulutuksen, työelämän ja yhteiskunnan välisiä ilmiöitä, suhteita, trendejä, muutoksia ja ennakointitietoa",
      "ohjauksen osana yhteiskunnan palvelurakenteita",
      "ohjauksen systeemisen merkityksen yksilön, yhteisöjen, palvelujen tuottajan ja yhteiskunnallisen kehityksen kannalta",
      "ohjauksen merkityksen koulutuspoliittisten, työmarkkinoiden, sosiaalisen osallisuuden, taloudellisen kehityksen sekä kestävän tulevaisuuden kannalta",
      "yhdenvertaisuuden, sosiaalisen oikeudenmukaisuuden ja yhteiskunnallisen monimuotoisuuden edistämisen merkityksen ohjauksessa",
      "oman tehtävän asemoinnin osana elinikäisen ja jatkuvan oppimisen sekä osaamisen kehittämisen kansallisia tavoitteita",
    ],
    osaa: [
      "hankkia ja arvioida ajantasaista tietoa koulutuksesta, työelämästä ja yhteiskunnallisista kysymyksistä",
      "ottaa työssään huomioon työelämän ja yhteiskunnan muutoksia ja tulevaisuutta koskevaa ennakkotietoa",
      "edistää yhteiskunnallisia rakenteita tiedostavaa ohjausta sekä yhdenvertaista, oikeudenmukaista ja monimuotoista yhteiskuntaa",
      "seurata ohjauksen järjestämiseen liittyvää tutkimusta ja julkista keskustelua",
      "paikantaa oman ja organisaationsa ohjauspalvelujen aseman ja roolin osana ohjauksen kansallista ekosysteemiä",
      "edistää omaa työtä ja sen toimintaedellytyksiä koskevan tutkimuksen suunnittelua ja toteutusta",
      "osallistua omaa työtä ja sen toimintaedellytyksiä koskevien linjausten muotoiluun",
    ],
  },
  "C1.2": {
    id: "C1.2",
    header: "Ohjauksen elinikäinen luonne",
    text: "Ohjaaja varmistaa, että elinikäisen ja jatkuvan oppimisen ytimenä ovat toimivat, asiakaslähtöiset, eettisesti korkeatasoiset sekä ammattimaisesti ja systeemisesti tuotetut elinikäisen ohjauksen palvelut yksilön ja ryhmien opiskelu- ja urapolkujen vaiheissa.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen jatkumona yksilön kaikissa elämänvaiheissa",
      "ohjauksen systeemisen merkityksen yksilön, yhteisöjen, palvelujen tuottajan ja yhteiskunnallisen kehityksen kannalta",
      "ohjauksen aseman ja merkityksen osana yksilön, ryhmien ja yhteisön elinikäistä ja jatkuvaa oppimista sekä osaamisen kehittämistä",
      "ohjauksen aseman eri hallinnonaloja läpäisevänä ja niitä yhdistävänä teemana",
      "eri hallinnonalojen erityiset kontekstit sekä lähtökohdat ohjauksen järjestämiselle",
      "monihallinnollisen ja monialaisen ohjauksen järjestämistä koskevat kansalliset ja kansainväliset periaatteet",
      "eri hallinnonalojen ohjausjärjestelyjen periaatteet ja työmuodot, jotka luovat pohjaa keskinäiselle yhteistyölle ja vertaisoppimiselle",
      "oman tehtävän asemoinnin osana elinikäisen ohjauksen paikallisia ja kansallisia järjestelyjä",
    ],
    osaa: [
      "ottaa työssään huomioon asiakkaan ja asiakasryhmien jäsenten elämäntilanteen osana elinikäistä ja jatkuvaa oppimista",
      "toimia erilaisten ja erilaisissa elämäntilanteissa olevien asiakasryhmien kanssa",
      "varmistaa katkeamattoman palveluketjun toimivuuden asiakkaiden siirtymävaiheissa",
    ],
  },
  "C1.3": {
    id: "C1.3",
    header: "Ohjauksen asema ja asemointi",
    text: "Ohjaaja seuraa ajankohtaista tutkimusta ja ottaa osaa alaan liittyvään yhteiskunnalliseen keskusteluun. Elinikäisen ohjauksen merkityksen ja lisäarvon osoittaminen keskeisten koulutus-, työ- ja elinkeino-, sekä hyvinvointi- ja kuntoutuspolitiikkojen tavoitteiden saavuttamisessa edellyttää ohjaajien ja päättäjien dialogia.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen systeemisen merkityksen yksilön, ryhmien, yhteisön, palvelujen tuottajan ja yhteiskunnallisen kehityksen kannalta paikallisesti, kansallisesti ja kansainvälisesti",
      "ohjauksen merkityksen yhdenvertaisuuden ja tasa-arvon sekä kestävän elämäntavan edistämisessä",
      "ohjauksen järjestämistä koskevan päätöksenteon prosessit",
      "keskeiset ohjauksen järjestämisestä vastuussa olevat päättäjät ja heidän vastuualueensa",
      "ohjausta ja sen tulosta sekä vaikuttavuutta koskevan arviointi- ja tutkimustiedon merkityksen ohjausta koskevan päätöksenteon pohjana",
    ],
    osaa: [
      "yksilöidä ohjausprosessin osatekijät ohjauksen aseman ja perustehtävän kuvaamiseksi asiakkaille, yhteistyötahoille, eri sidosryhmille ja päättäjille",
      "seurata aktiivisesti yhteiskunnallista kehitystä ja ajankohtaista keskustelua sekä monitieteistä tutkimusta ohjauksen näkökulmasta",
      "ottaa huomioon yhdenvertaisuuden, tasa-arvon ja kestävän elämäntavan edistämisen ohjauksessa",
      "ottaa huomioon yksilön elämälleen ja toiminnalleen asettamat tavoitteet sekä koulutusjärjestelmän ja työelämän toimivuuden tavoitteet",
      "osoittaa päättäjille ja palvelujen rahoittajille ohjauksen lisäarvon ja palvelujen toteuttamiseen sijoitettujen resurssien tuloksellisuuden ja vaikuttavuuden",
      "yksilöidä näyttöön perustuvat ohjauksen kehittämisen keskeisimmän kohteet",
      "yksilöidä tarvittavat toimenpiteet ja ratkaisuvaihtoehdot ohjausta koskevan päätöksenteon pohjaksi",
      "osallistua ohjauksen järjestämisen taustalla olevaan julkiseen keskusteluun",
      "sisällyttää palveluja koskevaa tutkimustietoa ja arviointituloksia ohjauksen järjestämistä koskevan päätöksenteon perustaksi",
      "osallistua alueellisen toimintamallin kehittämiseen ohjauksen aseman vakiinnuttamiseksi",
      "työskennellä paikallisten, kansallisten ja kansainvälisten yhteistyötahojen kanssa ohjauksen tavoitteiden ja toimintaedellytysten edistämiseksi",
    ],
  },
  "C2.1": {
    id: "C2.1",
    header: "Organisaatio- ja verkostoteoriat",
    text: "Ohjaaja soveltaa organisaatio- ja verkostoteorioita ohjauksen yhteisten tavoitteiden määrittämisessä sekä palvelujen suunnittelussa, toteutuksessa ja arvioinnissa. Ohjaaja toimii monialaisissa verkostoissa ja edistää palvelujen jatkuvuutta ja yhtenäisyyttä yksilön eri elämänvaiheissa.",
    tunnistaa_ja_ymmartaa: [
      "ohjauspalveluja tuottavat tahot ja organisaatiot yhteisöinä",
      "mitkä tekijät edistävät tai rajoittavat organisaation, verkostojen ja eri toimijoiden toimintaa yhteisen päämäärän saavuttamiseksi",
      "miten organisaation ja verkoston työtehtävät on jaettu eri toimijoiden kesken",
      "miten organisaatioiden ja verkostojen rakenteet (valta, johtajuus, vastuunjako) vaikuttavat ohjauksen järjestämiseen",
      "miten organisaation ja verkostojen toimintakulttuurit (toimintatavat, arvot, säännöt, kieli ja toimijoiden keskinäiset suhteet) vaikuttavat ohjauksen järjestämiseen",
      "miten organisaatiot luovat edellytykset ohjauspalvelujen tuottamiselle ja toiminnassa tarvittavalle sosiaaliselle vuorovaikutukselle",
      "miten organisaatio ja verkosto ovat osa ohjauksen ekosysteemiä (tavoitteet, säädökset, resurssit, toiminnan ja olemassaolon tarkoitus)",
    ],
    osaa: [
      "hahmottaa sekä oman että organisaationsa roolin ja aseman verkostotyössä",
      "hahmottaa organisaatioiden ja verkostojen rakenteiden sekä toimintatapojen vaikutukset ohjauksen järjestämiseen",
      "soveltaa organisaatio- ja verkostoteorioita ohjauksen yhteisten tavoitteiden määrittämisessä, ohjauksen suunnittelussa, toteutuksessa ja arvioinnissa.",
    ],
  },
  "C2.2": {
    id: "C2.2",
    header: "Strateginen oppiminen",
    text: "Ohjaaja näkee elinikäisen ohjauksen osana yksilön elämänsuunnittelua, laajempaa yhteiskunnallista kontekstia ja eri organisaatioiden palvelurakennetta. Ohjauksen järjestäminen ja kehittäminen liittyvät erikseen ohjauksen yhteiskunnalliseen tehtävään, palveluja tuottavien organisaatioiden toimintaan, ohjaushenkilöstön omaan osaamiseen sekä yksilöiden, ryhmien ja ohjaajien välisiin ohjausprosesseihin. Ohjaajan ja hänen yhteistyöverkostojensa oppiminen perustuu verkoston jäsenten yhteiseen oppimiseen. Verkostoissa yhteisesti suunniteltu toiminta voi tuoda myönteisiä muutoksia sekä ohjaajan omaan että yhteistyöverkostojen toimintaan.",
    tunnistaa_ja_ymmartaa: [
      "systeemisen ajattelun periaatteet ja merkityksen ohjauspalvelujen suunnittelulle ja toteutukselle",
      "strategisen oppimisen roolin ja merkityksen oman ohjaustyön ja omien yhteistyöverkostojen toiminnan jatkuvalle kehittämiselle",
      "keskeisimmät strategisen kehittämisen lähestymistavat",
    ],
    osaa: [
      "yksilöidä omaan ohjaustyöhön vaikuttavan toimintaympäristön muutostekijät yhdessä ohjauksen järjestämiseen osallistuvien toimijoiden ja sidosryhmien kanssa",
      "tarkastella ohjausta siinä kontekstissa, jossa sitä toteutetaan",
      "huomioida ohjauksen eri osatekijöiden väliset yhteydet",
      "yksilöidä oman ohjaustilanteen nykytilan ja sen keskeisimmät muutostarpeet",
      "arvioida ohjauksen palveluprosessia ja käyttää arviointituloksia ohjauksen suunnittelussa ja päätöksenteossa",
    ],
  },
  "C3.1": {
    id: "C3.1",
    header: "Moniammatillinen verkostotyö",
    text: "Ohjaaja tekee moniammatillista yhteistyötä muiden ammattilaisten kanssa yksilön ja ryhmien ohjausprosessien eri vaiheissa. Ohjauksen työnjakoa edellyttävät osa-alueet ovat toimintakyvyn ja hyvinvoinnin tuki, osaamisen kehittämisen sekä ura- ja elämänsuunnittelun ohjaus.",
    tunnistaa_ja_ymmartaa: [
      "ohjaustilanteisiin samanaikaisesti liittyvät useat rinnakkaiset näkökulmat ja taustatekijät",
      "eri organisaatioiden käyttämät erilaiset ohjaustyötä kuvaavat käsitteet ",
      "ohjauksen jatkumona ja luonteeltaan verkostomaisena",
      "oman organisaation ja alueellisten toimijoiden toimintasuunnitelman sekä yhteisesti sovitut kokonaistavoitteet ohjaukselle",
      "ohjauksen eri ammattilaisten vastuut, resurssit, osaamisalueet ja tehtävät paikallisissa verkostoissa",
      "oman roolin ja aseman verkostotyössä yksilöiden tai ryhmien ohjausprosessin eri vaiheissa",
      "oman ammatin ja osaamisen rajaukset ohjaustyössä",
    ],
    osaa: [
      "tarkastella asiakkaan tilanteita monesta eri näkökulmasta",
      "kohdata eri hallinnonaloilla työskenteleviä ohjaajia vertaisina",
      "kunnioittaa erilasia näkökulmia ohjauksessa ja sietää tilanteen monitulkintaisuutta",
      "avata omaa työtään koskevia käsitteitä ja lähtökohtia",
      "luoda eri näkökulmista yhteistä ymmärrystä eri toimijoiden moniammatillisen työn pohjaksi",
      "luoda tarkoituksenmukaisia yhteistyösuhteita",
      "toimia erilaisissa moniammatillisissa työryhmissä ja yhteistyöverkostoissa sekä sopia tarvittaessa asiakkaan mahdollisesta siirtymisestä muiden palveluverkoston toimijoiden asiakkaaksi",
      "varmistaa moniammatillisessa yhteistyössä, että vastuu asiakkaan ohjausprosessista on selvästi määritelty toimijoiden kesken",
      "yksilöidä moniammatillisen yhteistyön merkityksen oman organisaationsa ohjaustoiminnan kehittämisen kannalta",
    ],
  },
  "C3.2": {
    id: "C3.2",
    header: "Monialainen verkostotyö",
    text: "Ohjaaja toimii yhteistyössä muiden ohjauspalveluja tuottavien organisaatioiden kanssa alueellisten monialaisten ohjauspalvelujen kehittämiseksi ja hyvien käytäntöjen jakamiseksi. Monialaisessa yhteistyössä tarkastellaan ohjauspalveluja tuottavien organisaatioiden ja sidosryhmien suhdeverkostoja, organisaatioiden tehtävien ja suhteiden erilaisuutta sekä palvelujärjestelyjen liittymäpintaa ja yhteisiä palveluita. Monialaisessa verkostotyössä ohjaaja tekee yhteistyötä eri hallinnonalojen toimijoiden ja sidosryhmien kanssa yksilön ja ryhmien ohjausprosessien eri vaiheissa.",
    tunnistaa_ja_ymmartaa: [
      "yhteisesti sovitun ohjauksen alueellisen kokonaistavoitteen merkityksen",
      "yhteisen sopimisen ja tiedonmuodostuksen merkityksen",
      "alueelliset palvelujen käyttäjäryhmät sekä ohjauksen kysynnän ja tarpeen alueellisesti",
      "yhteistyökumppanit ja sidosryhmät sekä heidän mahdolliset odotuksensa ohjauksen tuloksesta",
      "keskeiset sidosryhmät ja ohjauksen järjestämisestä vastuussa olevat palvelujen tuottajat sekä heidän osaamisalueensa",
      "paikallisten ohjauspalvelujen tuottajien erityispiirteet palveluohjauksen tarpeiden näkökulmasta",
      "oman organisaation perustehtävän ja sen rajaukset alueellisessa monialaisessa ohjauksen palveluverkostossa",
    ],
    osaa: [
      "osallistua tarvittavan yhteistyöverkoston muodostamiseen alueellisten ohjauspalvelujen järjestämiseksi ",
      "osallistua alueellisen yhteistyöverkoston tai työryhmien työskentelyn organisointiin yhteistesti sovittujen tavoitteiden saavuttamiseksi",
      "suunnitella ja toteuttaa työtään monialaisessa verkostossa ottamalla huomioon sekä oman organisaation että alueen monialaisen verkoston tavoitteet ohjauksen järjestämiselle",
      "laatia oman organisaation ohjaustyön toimintasuunnitelman osana alueellista yhteistyöverkostoa",
      "sopia kunkin toimijan vastuut",
      "varmistaa riittävät toimintavaltuudet verkostossa toimimiseksi",
      "sopia yhteisen viestinnän, tiedottamisen ja tiedonvaihdon lähtökohdat",
      "sopia tarvittaessa asiakkaan kanssa hänen siirtymisestään muiden palveluverkoston toimijoiden asiakkaaksi",
      "varmistaa monialaisessa yhteistyössä, että vastuu asiakkaan ohjausprosessista on selvästi määritelty toimijoiden kesken",
      "kehittää omaa osaamistaan verkostoitumis- ja yhteistyötaidoissa",
      "edistää yhteisöllistä toimintaa verkostoissa",
    ],
  },
  "C4.1": {
    id: "C4.1",
    header: "Suunnittelu ja organistointi",
    text: "Ohjaaja suunnittelee ja organisoi työnsä alan eettisten periaatteiden, laatukriteereiden, paikallisten tarpeiden ja toimintaedellytysten sekä hallinnollisten ohjeiden ja säädösten mukaisesti.",
    tunnistaa_ja_ymmartaa: [
      "strategisen osaamisen merkityksen ohjaustyössä",
      "ohjauksen järjestämistä ohjaavat säädökset ja hallinnolliset ohjeet ",
      "palvelujen käyttäjäryhmät ",
      "ohjauksen osana organisaation kokonaistoimintaa ja sen toimintakulttuuria",
      "oman ohjaustyön osana alueellista ja paikallista ohjauksen ekosysteemiä",
      "käytössä olevat resurssit ja monialaiset kokonaisvoimavarat palvelujen tuottamiseksi",
      "lähtökohdat olemassa olevien monialaisten kokonaisvoimavarojen tarkoituksenmukaisimmalle ja tehokkaimmalle käytölle",
    ],
    osaa: [
      "laatia toimintasuunnitelman oman työnsä pohjaksi",
      "yksilöidä keskeisimmät käyttäjäryhmät oman hallinnonalan ohjauspalveluille",
      "tehdä palvelutarpeiden analyysin eri käyttäjäryhmille",
      "suunnitella ohjausta ja ohjauspalveluja osana oman organisaation toimintaa",
      "suunnitella ohjausta ja ohjauspalveluja osana alueellisten yhteistyöverkostojen palveluja",
      "luoda luottamuksen ja avoimuuden kulttuuria omassa työyhteisössään yhteistyön pohjaksi",
      "kehittää ohjausta ja ohjauspalveluja proaktiivisesti",
    ],
    context_competencies: [
      {
        context: "Perusopetus ja toinen aste",
        tunnistaa_ja_ymmartaa: [
          "kouluastetta koskevan lainsäädännön, opetussuunnitelman ja tutkintojen perusteet",
          "kouluastetta koskevat muut hallinnolliset ohjeet",
          "koulutuksen järjestäjän laatimat ja oppilaitoskohtaiset suunnitelmat",
        ],
        osaa: [
          "suunnitella, toteuttaa ja arvioida ohjausta kouluastetta koskevan lainsäädännön sekä toimintaa ohjaavien hallinnollisten ohjeiden, opetussuunnitelmien ja tutkintojen perusteiden mukaisesti",
          "suunnitella, toteuttaa ja arvioida koulutuksen järjestäjän toimesta laadittujen ja oppilaitoksen omien suunnitelmien mukaisesti",
        ],
      },
      {
        context: "Korkea-aste",
        tunnistaa_ja_ymmartaa: [
          "korkea-astetta koskevan lainsäädännön, hallinnolliset ohjeet ja tutkintojen perusteet",
          "oppilaitoskohtaiset toimintaa ohjaavat suunnitelmat",
        ],
        osaa: [
          "suunnitella, toteuttaa ja arvioida ohjausta korkea-astetta koskevan lainsäädännön ja hallinnollisten ohjeiden mukaisesti",
          "suunnitella, toteuttaa ja arvioida ohjausta oman oppilaitoksen suunnitelmien mukaisesti",
        ],
      },
      {
        context: "Työvoimapalvelut",
        tunnistaa_ja_ymmartaa: [
          "Työvoimapalvelujen toimintaa koskevan lainsäädännön sekä kansalliset ja alueelliset ohjeet",
          "oman organisaation yhteiset toimintaperiaatteet, suunnitelmat ja ohjeet",
        ],
        osaa: [
          "suunnitella, toteuttaa ja arvioida ohjauspalveluja lainsäädännön sekä hallinnollisten ja organisaatioiden toimintaohjeiden mukaisesti",
          "kunnioittaa yksilöiden oikeutta itsemääräämiseen ja autonomiaan sopusoinnussa muiden velvoitteiden kanssa",
        ],
      },
      {
        context: "Ohjaamot",
        tunnistaa_ja_ymmartaa: [
          "Ohjaamojen toimintaa koskevan lainsäädännön sekä kansalliset ja alueelliset ohjeet",
          "oman organisaation yhteiset toimintaperiaatteet, suunnitelmat ja ohjeet",
        ],
        osaa: [
          "suunnitella, toteuttaa ja arvioida ohjauspalveluja kansallisten, alueellisten ja organisaatioiden toimintaohjeiden mukaisesti",
        ],
      },
    ],
  },
  "C4.2": {
    id: "C4.2",
    header: "Laadun arviointi ja seuranta",
    text: "Ohjaaja edistää ohjauksen systemaattista laadun ja vaikuttavuuden arviointia. Ohjauksen vaikuttavuuden ja laadun arviointi kattaa palvelujen tuloksen, palvelun toteutuksen ja palveluprosessin, palvelurakenteet, ohjauksen toteuttamiseen varatut kokonaisvoimavarat sekä koko laadun arvioinnin lähestymistavat ja organisaation laatukulttuurin.",
    tunnistaa_ja_ymmartaa: [
      "ohjauksen tuloksen, vaikuttavuuden ja laadun yhteiskunnan, palvelujen tuottajien ja asiakkaiden näkökulmista",
      "keskeisimmät laadun arvioinnin määrälliset ja laadulliset lähestymistavat ja työvälineet",
      "eri käyttäjäryhmät ja heidän palvelutarpeensa ",
      "miten hyvin ohjaus kohtaa kysynnän ja vastaa yksilöiden, ryhmien ja eri sidosryhmien tarpeisiin",
      "miten hyvin yksilöt ja ryhmät edistyvät asetettujen tavoitteiden saavuttamisessa",
      "miten saavutettavia ohjauspalvelut ovat",
      "miten toimivia asiakassuhteet ovat ohjauksessa",
      "miten asiakkaat kokevat palvelun",
      "miten palveluja johdetaan",
      "keskeisimmät kriteerit laadukkaalle ohjaukselle",
      "miten toimia verkostoissa ohjauksen ja ohjauksen laadun kehittämiseksi",
      "miten dokumentoida ohjausprosesseja ja niiden tuloksia tiedolla johtamisen perustaksi",
      "miten osaaminen ja toiminta vaikuttavat ohjauksen kokonaislaatuun",
    ],
    osaa: [
      "yksilöidä oman työnsä keskeisimpiä tuloksia yksilön, oman organisaation ja yhteiskunnan näkökulmista",
      "yksilöidä tarvittavat määrälliset ja laadulliset kriteerit sekä tarvittavan tiedon ohjauksen tuloksen osoittamiseksi",
      "suunnitella työmuotoja, joissa ohjauksen mahdollinen tulos on dokumentoitavissa",
      "dokumentoida oman työnsä tuloksellisuutta ja vaikuttavuutta",
      "koota systemaattisesti määrällistä ja laadullista tietoa ohjauksen toteutumisesta",
      "tulkita ohjauksen järjestämistä koskevaa tietoa palvelujen laadun ja vaikuttavuuden näkökulmasta",
      "yhdistää ohjauksen järjestämistä koskevaa tietoa muuhun käytettävissä olevaan tutkimus- ja arviointitietoon",
      "kehittää omaa työtään saatujen arviointitulosten perusteella",
      "jakaa arviointitietoa työn systemaattisen kehittämisen perustaksi kollegoille, tutkijoille ja päättäjille",
      "seurata alan tutkimusta, jota voi soveltaa oman työn kehittämiseen",
    ],
  },
  "C4.3": {
    id: "C4.3",
    header: "Jatkuva kehittäminen",
    text: "Ohjaaja tarkastelee ohjauksen taustalla olevia periaatteita, arvoja, käsityksiä, rakenteita, linjauksia ja käytännön toimintaedellytyksiä. Strategiseen oppimiseen perustuva ohjauspalvelujen jatkuva kehittäminen kattaa palvelujen arvioinnin ja analyysin, visioinnin, viestinnän ja toiminnan sekä palvelujen jatkuvan oppimisen.",
    tunnistaa_ja_ymmartaa: [
      "globaalien ja yhteiskunnallisten trendien ja muutosten vaikutukset talouteen ja työelämään",
      "miten yhteiskunnassa tapahtuvat muutokset määrittävät ohjauksen järjestämisen lähtökohtia",
      "eri asiakasryhmien tarpeet ja sidosryhmien odotukset, jotka vaikuttavat ohjauksen järjestämisen lähtökohtiin",
      "keskeiset ohjauksen järjestämiseen liittyvät teoriat, arvot, kansalliset linjaukset ja säädökset",
      "keskeiset ohjauksen järjestämiseen yhteydessä olevat sidosryhmät",
      "ohjauksen järjestämistä koskevan päätöksenteon prosessit",
      "ohjauksen järjestämistä varten käytössä olevat kokonaisvoimavarat",
      "keskeiset monialaisen ohjauksen järjestelyjen osatekijät",
    ],
    osaa: [
      "ottaa kehittämistyössään huomioon keskeisimmät ohjausprosessin osatekijät ja mekanismit",
      "hankkia ja arvioida ajantasaista tietoa koulutuksesta, työelämästä ja yhteiskunnallisista kysymyksistä",
      "laatia tilannearvion omasta toimintaympäristöstään",
      "kriittisesti arvioida ja hyödyntää tulevaisuutta koskevaa ennakointitietoa",
      "yksilöidä keskeisimmät ohjauksen järjestämiseen liittyvät trendit ja ulkoiset muutostekijät",
      "ymmärtää oman ja yhteisönsä roolin tulevaisuuden rakentamisessa",
      "yksilöidä oman ja yhteistyöverkon toiminnan vahvuudet ja kehittämiskohteet",
      "käyttää ohjauksen palaute- ja arviointitietoa oman ja yhteistyöverkoston toiminnan uudelleen suunnittelussa ja kehittämisessä",
      "laatia omaa työtä koskevan toiminta- ja kehittämissuunnitelman ja sen tavoitteille mitattavissa olevat seurantakohteet",
      "osallistua yhteistyöverkoston jatkuvaan kehittämiseen ja yhteisen toimintasuunnitelman laatimiseen",
      "osallistua yhteistyöverkoston työskentelyn organisointiin yhteisten tavoitteiden saavuttamiseksi ",
      "toimia proaktiivisesti yhteisten kokonaisvoimavarojen ja toimintavaltuuksien varmistamiseksi",
      "dokumentoida ja raportoida systemaattisesti verkostotyön tuloksia yhteisesti sovittujen periaatteiden mukaisesti",
      "vakiinnuttaa uudet työmuodot ja niiden systemaattisen arvioinnin oman jatkuvan oppimisen ja kehittämisen pohjaksi ",
    ],
  },
};
