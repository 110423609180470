import React from 'react';
import { RadarElement } from './radar.js';

export function Results({
  radarValues,
  changeToCompetenceGoalsForm,
  competenceGoalsState,
}) {
  return (
    <section>
      <div className="line"></div>

      <h2>Osaamiskartta</h2>
      {!competenceGoalsState ? (
        <>
          {' '}
          <p className="content-area">
            Alla oleva osaamiskartta havainnollistaa osaamista. Tarkastele
            kuviota. Mitkä alueet ovat vahvimpia? Millä alueilla on eniten
            kehittymisen mahdollisuuksia?
          </p>
          <p className="content-area">
            Voit nyt asettaa itsellesi tavoitetason ja verrata sitä
            tämänhetkiseen osaamistasoosi. Aloita tavoitteiden asettaminen alla
            olevasta painikkeesta.
          </p>
          {!competenceGoalsState && (
            <button
              onClick={() => changeToCompetenceGoalsForm()}
              className="submit-button"
            >
              Aseta osaamistavoitteesi
            </button>
          )}
        </>
      ) : (
        <>
          <p className="content-area">
            Punainen väri edustaa nykyistä osaamista ja sininen asetettua
            tavoitetasoa. Katso syntyneitä kuvioita ja vertaa niitä. Käytä
            syntynyttä visualisointia pohtiaksesi, millä osaamisalueilla on
            eniten kehittymisen mahdollisuuksia. Käytä havaintoja osaamisen
            kehittämisen suunnittelun tueksi.
          </p>
        </>
      )}

      <RadarElement values={radarValues} competenceGoalsState={competenceGoalsState} />
    </section>
  );
}
