import React from 'react';
import Modal from 'react-modal';
import { questionInformation } from '../questions.js';
import { Context } from './Context.js';

Modal.setAppElement('#root');

export function InfoModal({ id, isOpen, closeModal, headerID }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      {questionInformation[id] && (
        <div className="modal-content">
          <header>
            <h2>
              <span className={headerID}>{questionInformation[id].id} </span>
              {questionInformation[id].header}
            </h2>
            <button onClick={closeModal}>&#x2715;</button>
          </header>
          <section>
            <p>{questionInformation[id].text}</p>
            <p className="bold">Ohjaaja tunnistaa ja ymmärtää</p>
            <ul>
              {questionInformation[id].tunnistaa_ja_ymmartaa.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
            <p className="bold">Ohjaaja osaa</p>
            <ul>
              {questionInformation[id].osaa.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </section>
          {questionInformation[id].hasOwnProperty('context_competencies') ? (
            <Context
              contextArray={questionInformation[id].context_competencies}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </Modal>
  );
}
