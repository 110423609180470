import React from 'react';
import { SkillLevel } from './SkillLevel.js';
import { levelDescriptions } from './questions.js';

export function Instructions() {
  return (
    <section>
      <p className="content-area">
        Alla olevan työvälineen avulla ohjausalan ammattilaiset, tiimit,
        verkostot ja organisaatiot voivat arvioida omaa ohjausosaamistaan
        suhteessa
        <span className="italics">
          {' '}
          Ohjausalan ammattilaisten osaamiskuvauksiin
        </span>
        . Löydät kuhunkin osaamislausekkeeseen liittyvän osaamiskuvauksen
        &#9432;-painikkeesta. Arviointilomakkeeseen annettuja tietoja ei
        tallenneta.
      </p>
      <h3 className="bold">Ohje</h3>
      <div className="content-area">
        <span className="bold">Ensimmäinen kierros: oma osaaminen</span>{' '}
        <p>
          Täytä lomake arvioiden nykyistä osaamistasi. Lue osaamislausekkeet ja
          valitse kutakin lauseketta koskien se osaamistaso (0–3), joka
          parhaiten kuvaa osaamistasi arviointihetkellä. Osaamistasot on kuvattu
          alla olevassa laatikossa. Vastattuasi kaikkiin osaamislausekkeisiin
          saat tulokseksi visuaalisen osaamiskartan.
        </p>
      </div>
      <div className="content-area">
        <span className="bold">
          Toinen kierros: tavoitetaso ja oma osaaminen
        </span>
        <p>
          Halutessasi lue osaamiskuvauksiin liittyvät osaamislausekkeet
          uudelleen ja määrittele nyt
          <span className="bold"> osaamistavoitteesi</span> jokaiselle
          osaamisalueelle. Näet kullekin osaamisalueelle aiemmin määrittelemäsi
          osaamistason lausekkeen alta. Saat tulokseksi visuaalisen
          osaamiskartan, jossa näkyvät sekä tämänhetkinen että tavoittelemasi
          osaamistaso.
        </p>
      </div>
      <div className="box info-box">
        <div className="content-area">
          <h3>Osaamistasot</h3>
          {Object.values(levelDescriptions).map((description) => (
            <SkillLevel key={description.header} level={description} />
          ))}
          <p className="italics">
            Saat yllä kuvatut osaamistasot näkyviin työvälineessä kunkin
            osaamislausekkeen kohdalla, kun siirrät hiiren osoittimen
            osaamistasoa kuvaavan arvon kohdalle.
          </p>
        </div>
      </div>
    </section>
  );
}
