import React from 'react';
import { useRef, useCallback } from 'react';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  plugin
);

export const data = {
  labels: [
    'A1.1 Laillinen ja hallinnollinen perusta',
    'A1.2 Ammatillinen vastuu',
    'A1.3 Eettiset periaatteet',
    'A1.4 Ohjaussuhde',
    'A2.1 Teoriatieto',
    'A2.2 Teoriatiedon soveltaminen',
    'A2.3 Ohjausalan tutkimus',
    'A3.1 Reflektiivinen ammattikäytäntö',
    'A3.2 Oman osaamisen kehittäminen',
    'A4.1 Selkeä viestintä',
    'A4.2 Tavoitteellinen vuorovaikutus',
    'A4.3 Refleksiivinen viestintä',
    'A4.4 Asiantuntijaviestintä',
    'A5.1 Teknologia ohjauksessa',
    'A5.2 Teknologia ohjausprosessissa',
    'A5.3 Teknologian yhteisöllinen käyttö',
    'A5.4 Teknologian ohjauskäytön systemaattinen kehittäminen',
    'B1.1 Ohjausprosessin osatekijät',
    'B1.2 Ohjaus prosessina',
    'B1.3 Ohjausprosessin tulos ja vaikuttavuus',
    'B2.1 Kehitys- ja elämänkulkuteoriat',
    'B2.2 Urateoriat',
    'B2.3 Yhteiskunta- ja työelämä',
    'B2.4 Ohjausteoriat',
    'B2.5 Elinikäinen ja jatkuva oppiminen',
    'B2.6 Ohjausfilosofia',
    'B3.1 Tiedonhankinta ja -hallinta',
    'B3.2 Tavoitteiden tarkastelu ja asettaminen',
    'B3.3 Asiakaslähtöinen ohjausprosessi',
    'B3.4 Arviointi',
    'C1.1 Ohjaus yhteiskunnassa',
    'C1.2 Ohjauksen elinikäinen luonne',
    'C1.3 Ohjauksen asema ja asemointi',
    'C2.1 Organisaatiot ja verkostot',
    'C2.2 Strateginen oppiminen',
    'C3.1 Moniammatillinen verkostotyö',
    'C3.2 Monialainen verkostotyö',
    'C4.1 Suunnittelu ja organisointi',
    'C4.2 Laadun arviointi ja seuranta',
    'C4.3 Jatkuva kehittäminen',
  ],
};

const options = {
  plugins: [plugin],
  scales: {
    r: {
      pointLabels: {
        callback: function (label, index) {
          if (label === 'A1.1 Laillinen ja hallinnollinen perusta') {
            return 'A1.1 Laillinen ja...';
          } else if (label === 'B2.1 Kehitys- ja elämänkulkuteoriat') {
            return 'B2.1 Kehitys- ja...';
          } else {
            return label;
          }
        },
      },
      ticks: {
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  },
  scale: {
    min: 0,
    max: 3,
    ticks: {
      beginAtZero: true,
      max: 3,
      min: 0,
      stepSize: 1,
    },
  },
};

export function RadarElement({ values, competenceGoalsState }) {
  let ref = useRef(null);

  const downloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.download = 'osaamiskartta.jpeg';
    link.href = ref.current.toBase64Image('image/jpeg', 1);
    link.click();
  }, []);
  if (!values.present.includes(null)) {
    data.datasets = [
      {
        label: 'Tämänhetkinen osaamistaso',
        data: values.present,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ];
  }
  if (!values.goal.includes(null)) {
    data.datasets = [
      ...data.datasets,
      {
        label: 'Tavoite',
        data: values.goal,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        borderWidth: 1,
      },
    ];
  }

  return (
    <div>
      <p className="content-area">
        Voit {!competenceGoalsState && <span>myös</span>} ladata osaamiskarttasi
        kuvana<span> </span>
        <button type="button" className="link-button" onClick={downloadImage}>
          tästä
        </button>
        .
      </p>
      <Radar ref={ref} data={data} options={options} />
    </div>
  );
}
