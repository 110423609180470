import React from 'react';
import { useState } from 'react';
import { initialQuestions, structure } from './questions.js';
import { Results } from './Results.js';
import { Question } from './Question.js';
import { Instructions } from './Instructions.js';
import { InvalidInputsErrorBox } from './InvalidInputsErrorBox.js';

import './styles.css';

export default function App() {
  const [questions, setQuestions] = useState(initialQuestions);
  const [showResults, setshowResults] = useState(false);
  const [competenceGoalsState, setcompetenceGoalsState] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState(false);

  const presentOrGoalState = competenceGoalsState === true ? 'goal' : 'answer';

  const ref = React.useRef();

  function handleState(a) {
    const newState = { ...questions };
    if (competenceGoalsState) {
      newState[a.id] = { ...newState[a.id], goal: a.value };
    } else {
      newState[a.id] = { ...newState[a.id], answer: a.value };
    }
    setQuestions({ ...newState });
  }
  function submit(e) {
    e.preventDefault();
    if (
      Object.values(questions).every(
        (question) => question[presentOrGoalState] !== null
      )
    ) {
      setshowResults(true);
      setInvalidInputs(false);
    } else {
      setInvalidInputs(true);
    }
  }
  function changeToCompetenceGoalsForm() {
    setshowResults(false);
    setcompetenceGoalsState(true);
    ref.current.scrollIntoView();
  }

  const values = {
    present: Object.values(questions).map((value) => value.answer),
    goal: Object.values(questions).map((value) => value.goal),
  };
  return (
    <div className="content">
      <Instructions />
      <div className="line"></div>
      {!competenceGoalsState ? (
        <h1 ref={ref}>Ensimmäinen kierros: oma osaaminen</h1>
      ) : (
        <h1 ref={ref}>Toinen kierros: tavoitetaso ja oma osaaminen</h1>
      )}

      <form id="form" className="content-area">
        {structure.map((header) => {
          return (
            <div
              key={header.id}
              className={`background ${header.id}-background`}
            >
              <Header key={header.id} id={header.id} header={header.header} />
              {header.subheaders.map((subheader) => {
                return (
                  <div key={subheader.id}>
                    <SubHeader
                      key={subheader.id}
                      id={subheader.id}
                      headerID={header.id}
                      header={subheader.subheader}
                    />
                    {subheader.questions.map((item) => {
                      if (questions[item] && !competenceGoalsState) {
                        return (
                          <Question
                            invalidInputs={invalidInputs}
                            isQuestionNull={questions[item].answer}
                            key={questions[item].id}
                            id={questions[item].id}
                            headerID={header.id}
                            question={questions[item].question}
                            change={handleState}
                          />
                        );
                      }
                      if (questions[item] && competenceGoalsState) {
                        return (
                          <Question
                            invalidInputs={invalidInputs}
                            present={questions[item].answer}
                            isQuestionNull={questions[item].goal}
                            competenceGoalsState={competenceGoalsState}
                            key={questions[item].id}
                            id={questions[item].id}
                            headerID={header.id}
                            question={questions[item].question}
                            change={handleState}
                          />
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </form>
      {!showResults && (
        <button form="form" onClick={submit} className="submit-button">
          Tulokset
        </button>
      )}

      {invalidInputs && (
        <InvalidInputsErrorBox
          questions={questions}
          presentOrGoal={presentOrGoalState}
        />
      )}
      {showResults && (
        <>
          <Results
            radarValues={values}
            changeToCompetenceGoalsForm={changeToCompetenceGoalsForm}
            competenceGoalsState={competenceGoalsState}
          />
        </>
      )}
    </div>
  );
}

const Header = ({ header, id }) => {
  return (
    <h2>
      <span className={id}>{id}</span> {header}
    </h2>
  );
};
const SubHeader = ({ header, id, headerID }) => {
  return (
    <h3>
      <span className={headerID}>{id}</span> {header}
    </h3>
  );
};
