import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import '../styles/accordion.css';

export function Context({ contextArray }) {
  return (
    <section>
      <h3>Kontekstiin liittyvä osaaminen</h3>
      <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
        {contextArray.map((contextCompetency, id) => {
          return (
            <AccordionItem key={id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span>{contextCompetency.context}</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="bold">Ohjaaja tunnistaa ja ymmärtää</p>
                <ul>
                  {contextCompetency.tunnistaa_ja_ymmartaa.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
                <p className="bold">Ohjaaja osaa</p>
                <ul>
                  {contextCompetency.osaa.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </section>
  );
}
